<template>
  <div>
    <div>
      <el-row style="margin: 10px 0px 30px 0px;">
        <el-col :span="3">
          <el-button icon="el-icon-check" class="hiaButton" plain round size="mini" @click="runAcknowledgeNoChanges()">Acknowledge No Changes</el-button>
        </el-col>
        <el-col :span="18">
          <center class="stats">
            <el-badge v-for="stat in ipListStatistics" :key="stat.key" class="item" :class="{ 'stats-loading': statsLoading }" :value="stat.count">
              <el-tag type="primary" size="small">{{ stat.key }}</el-tag>
            </el-badge>
          </center>
        </el-col>
        <el-col :span="3">
          <el-row :gutter="2">
            <el-col :span="24">
              <el-input id="globalSearch" type="text" size="mini" placeholder="filter all..." v-model="filterCriteria" v-on:keyup.native="runGlobalFilter($event)"> </el-input>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <div style="margin-top: 10px; min-height: 200px;">
          <!-- <el-col :span="24" style="margin-bottom: 10px; height: 33px;">
            <el-button class="hiaButton" plain round v-if="getSelectedRecords().length > 0" size="mini" @click="showMassUpdateDialog = true">Mass Update</el-button>
          </el-col> -->

          <v-server-table class="hiaTable" id="coderReviewListGrid" ref="coderReviewListGrid" @loading="tableLoader = true" @loaded="tableLoader = false" :columns="columns" :options="options" v-loading="tableLoader" element-loading-spinner="atom-audit-loader">
            <template slot="selected" slot-scope="props">
              <input type="checkbox" v-model="props.row.checked" @click="selectRecord(props.row, $event)" />
            </template>
            <template slot="patientNumber" slot-scope="props">
              <router-link :to="`/my-projects/inpatient/${props.row.drgTableId}`">{{ props.row.patientNumber }}</router-link>
            </template>
            <template slot="dischargeDate" slot-scope="props">
              <div>
                {{ props.row.dischargeDate ? formatDate(props.row.dischargeDate) : '' }}
              </div>
            </template>
            <template slot="admitDate" slot-scope="props">
              <div>
                {{ props.row.admitDate ? formatDate(props.row.admitDate) : '' }}
              </div>
            </template>
            <template slot="reviewDate" slot-scope="props">
              <div>
                {{ props.row.reviewDate ? formatDate(props.row.reviewDate) : '' }}
              </div>
            </template>
            <template slot="level2ReasonForChage" slot-scope="props">
              <div>
                {{ props.row.reviewLevel !== 'Coding Review' ? props.row.level2ReasonForChage : '' }}
              </div>
            </template>
            <template slot="hasAP" slot-scope="props">
              <div v-if="props.row.hasAP != null">
                <center>
                  <i class="el-icon-check checkSize" :title="props.row.hasAP"></i>
                </center>
              </div>
            </template>
            <template slot="rebuttalDate" slot-scope="props">
              <div>
                {{ props.row.rebuttalDate ? formatDate(props.row.rebuttalDate) : '' }}
              </div>
            </template>
          </v-server-table>
        </div>
      </el-row>
    </div>
    <div id="dialogs">
      <coderInpatientWorksheetModal v-if="showWorksheetModal" :drgTableId="worksheetDrgTableId" :showWorksheetModal="showWorksheetModal" @close="handleModalClose"> </coderInpatientWorksheetModal>
      <MyProjectsInpatientMassUpdate v-if="showMassUpdateDialog" @massupdate="handleMassUpdate" @close="showMassUpdateDialog = false" :localDropdowns="dropdowns" :showMassUpdateDialog="showMassUpdateDialog" :selectedRecords="ipListSelected" :selectedClients="[]"> </MyProjectsInpatientMassUpdate>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'

  import dateHelper from '@/mixins/date-helper'
  import hiaTable from '@/mixins/hiaTable'
  import debounce from 'debounce'
  import MyProjectsInpatientMassUpdate from './MyProjectsInpatientMassUpdate'

  export default {
    name: 'inpatientList',
    mixins: [dateHelper, hiaTable],
    data() {
      return {
        showMassUpdateDialog: false,
        statsLoading: false,
        vueTables2Query: {},
        loading: true,
        showWorksheetModal: false,
        worksheetDrgTableId: null,
        filterCriteria: '',
        tableLoader: false,
        isActivated: true,
        columns: ['patientNumber', 'patientType', 'consultantNumber', 'mrNumber', 'payorName', 'coder', 'cdiStaff', 'physicianName', 'dischargeDate', 'grouper', 'reasonForChange', 'originalDrg', 'newDrg', 'patientAge', 'originalPoo', 'disposition', 'recommendedPatStatus', 'recommendedPoo', 'reviewDate', 'status', 'auditResult', 'agreeWithCodes', 'responseNeeded', 'rebuttalDate', 'finalOutcome'],
        options: {
          actionsPosition: 'right',
          requestFunction: data => {
            return this.requestData(data)
          },
          debounce: 600,
          filterByColumn: true,
          listColumns: {
            reviewLevel: []
          },
          columnsClasses: {
            selected: 'selected',
            comments: 'customComments',
            delete: 'delete'
          },
          headings: {
            selected: h => {
              return h('input', {
                attrs: {
                  type: 'checkbox',
                  id: 'selectAllCheckboxIpList'
                },
                on: {
                  click: e => {
                    this.localselectAll(e.srcElement.checked)
                  }
                },
                ref: 'selectAllCheckbox'
              })
            },
            patientNumber: 'Patient Number',
            agreeWithCodes: 'Agree with Changes',
            finalOutcome: 'Final Outcome',
            patientType: 'Pat Type',
            status: 'Review Status',
            consultantNumber: 'Auditor',
            payorName: 'Payor',
            physicianName: 'Provider',
            dischargeDate: 'Discharge Date',
            reasonForChange: 'Reason F Change',
            originalDrg: 'Orig. DRG',
            newDrg: 'New DRG',
            patientAge: 'Age',
            originalPoo: 'POO',
            disposition: 'Pat Status',
            recommendedPatStatus: 'Rec Pat Status',
            ipreviewType: 'Review Type',
            excludeRecord: 'Exclude Record',
            dedicatedStaffReview: 'Dedicated Staff Review',
            recommendedPoo: 'Rec POO',
            subClientNumber: 'Sub Client',
            originalReviewer: 'Orig Reviewer',
            originalReviewDate: 'Orig Review Date',
            originalClientNumber: 'Orig Client',
            level2ReasonForChage: 'Lvl2 Reason F Change',
            level2Drg: 'Lvl2 DRG',
            level2PatientStatus: 'Lvl2 Pat Status',
            level2Poo: 'Lvl2 POO',
            reviewLevel: 'Review Level',
            cdiStaff: 'CDI Staff',
            comments: 'Comments',
            mrNumber: 'MR Number',
            recordStatus: 'Record Status',
            reviewDate: 'Review Date',
            rebuttalCount: 'Rebuttal Count',
            rebuttalActiveCount: 'Active Rebuttals',
            rebuttalRespondedCount: 'Completed Rebuttals',
            hasAP: 'Has Action Plan',
            auditResult: 'Audit Result',
            responseNeeded: 'Response Needed',
            responseCount: 'Response Count',
            rebuttalDate: 'Response Date',
            delete: ''
          },
          perPage: 25,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
          // columnsDropdown: true
        }
      }
    },
    methods: {
      ...mapActions('coder/', ['LOAD_CODER_IP_LIST_PAGINATED', 'RUN_IP_MASS_ACKNOWLEDGE_NO_CHANGES']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS', 'CONVERT_DROPDOWN_COLUMNFILTERARRAY']),
      ...mapMutations('inpatientReviews/', ['SET_IPLIST_SELECTED', 'CLEAR_IPLIST_SELECTED', 'SET_IPLIST_REFRESH_FUNC']),
      async requestData(data) {
        try {
          data.projectID = this.$route.params.id
          data.query.projectID = this.$route.params.id
          data.globalSearch = this.filterCriteria
          this.vueTables2Query = data
          this.statsLoading = true
          const resultArray = await this.LOAD_CODER_IP_LIST_PAGINATED(data)
          this.statsLoading = false
          return resultArray
        } catch (err) {
          console.log(err)
        }
      },
      openWorksheetModal(row) {
        this.worksheetDrgTableId = row.drgTableId
        if (this.$route.query.id !== row.drgTableId) {
          this.$router.push({ query: { id: row.drgTableId } })
        }
        this.showWorksheetModal = true
      },
      getSelectedRecords() {
        const simpleArray = this.ipListSelected.map(y => y.drgTableId)
        return [...new Set(simpleArray)]
      },
      handleModalClose() {
        this.showWorksheetModal = false
        this.$router.push({ query: {} })
      },
      async localselectAll(checked) {
        const table = this.$refs.coderReviewListGrid.$el
        const checkboxes = table.querySelectorAll('input[type ="checkbox"]')

        if (checked) {
          this.tableLoader = true
          await this.GET_IP_LIST_ALLIDS(this.vueTables2Query)
          for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = true
          }
          this.tableLoader = false
          // this.$refs.coderReviewListGrid.refresh()
        } else {
          this.CLEAR_IPLIST_SELECTED()
          for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false
          }
          // this.$refs.coderReviewListGrid.refresh()
        }
      },
      runGlobalFilter: debounce(function() {
        this.$refs.coderReviewListGrid.setPage(1)
      }, 400),
      async handleMassUpdate(massUpdateModel) {
        this.showMassUpdateDialog = false
        // this.localSelectAll(false) // clear all the selected checkboxes
        // this.$refs.coderReviewListGrid.getData()
      },
      runAcknowledgeNoChanges() {
        this.$confirm('Acknowledge No Changes for records without changes?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          const payload = {
            projectID: this.$route.params.id,
            rebuttalDate: new Date().toISOString()
          }
          this.tableLoader = true
          this.RUN_IP_MASS_ACKNOWLEDGE_NO_CHANGES(payload).then(
            () => {
              this.$refs.coderReviewListGrid.getData()
              this.tableLoader = false
              this.$message({
                message: 'Acknowledge No Changes Successful',
                type: 'success'
              })
            },
            error => {
              console.log(error)
              this.tableLoader = false
              const message = error.response.data.message || 'Acknowledge No Changes Error'
              this.$message({
                message: message,
                type: 'error'
              })
            }
          )
        })
      }
    },
    computed: {
      ...mapState('inpatientReviews/', ['statistics', 'ipListSelected', 'ipListRecordValidations']),
      ...mapState('managedLists/projects/', ['projectPK', 'project']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('coder/', ['ipListStatistics'])
      // ...mapState('profile/', ['userProfile'])
    },
    created: function() {
      // this.defaultColumns = JSON.parse(JSON.stringify(this.sortableColumns))
      // this.loading = true
    },
    mounted: function() {
      if (this.$route.query.id) {
        this.openWorksheetModal({ drgTableId: this.$route.query.id })
      }
    },
    activated: function() {
      if (this.$refs.coderReviewListGrid && !this.isActivated) {
        this.$refs.coderReviewListGrid.getData()
      }
      this.isActivated = true
    },
    deactivated: function() {
      this.isActivated = false
      this.localInpatientReviews = []
      // clear any old validation messages, validation messages can be left around because the form validation gets triggered natively by
      // the browser when users hit the enter key in the form
      if (this.$refs.addForm) {
        this.$refs.addForm.clearValidate()
      }
      // this.localselectAll(false)
      // this.CLEAR_IPLIST_SELECTED()
    },
    components: {
      MyProjectsInpatientMassUpdate
    }
  }
</script>

<style scoped>
  .item {
    margin-top: 3px;
    margin-right: 35px;
  }

  .stats-loading {
    opacity: 0.5;
  }
</style>
