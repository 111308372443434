<template>
  <div id="dialogs">
    <el-dialog :visible="showMassUpdateDialog" width="70%" :before-close="handleClose">
      <template slot="title">
        <template v-if="selectedRecords.length > 0">
          <div class="el-dialog__title">Mass Update {{ selectedRecords.length }} Record{{ selectedRecords.length > 1 ? 's' : '' }}</div>
          <small v-if="!loading" style="font-style: italic; display: inline-block;">Select the toggles to update individual fields</small>
        </template>
        <template v-else>
          No Records Selected
        </template>
      </template>
      <div v-if="loading" v-loading="loading" element-loading-spinner="atom-audit-loader" style="height: 390px; margin-top: -50px;"></div>

      <el-form v-loading="runningMassUpdate" element-loading-spinner="atom-audit-loader" size="mini" label-position="left" label-width="120px" :model="massUpdate.ascTableMassUpdateFields" ref="massUpdate" v-if="selectedRecords.length > 0 && !loading">
        <el-row>
          <el-row>
            <el-col :span="3">
              <el-switch v-model="massUpdate.updateProject" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
            </el-col>
            <el-col :span="18">
              <el-form-item size="mini" ref="projectFormItem" label="Project" prop="projectID">
                <projectSelector v-model="massUpdate.ascTableMassUpdateFields.projectID" :project="selectedProject" @selected="selectedProject = $event" :disabled="!massUpdate.updateProject" :defaultQuery="{ projectTypeId: project.projectTypeId }"> </projectSelector>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="3">
              <el-switch v-model="massUpdate.updateClientNumber" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
            </el-col>
            <el-col :span="18">
              <el-form-item ref="clientNumberFormItem" label="Facility" prop="clientNumber">
                <hia-el-select :disabled="!massUpdate.updateClientNumber" v-model="massUpdate.ascTableMassUpdateFields.clientId" placeholder="Facility" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.clients.list"> </hia-el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="3">
              <el-switch v-model="massUpdate.updateDate" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-col>
            <el-col :span="18">
              <el-form-item size="mini" ref="dateFormItem" label="Review Date" prop="date">
                <el-date-picker :disabled="!massUpdate.updateDate" size="mini" type="date" v-model="massUpdate.ascTableMassUpdateFields.date" id="date" format="MM/dd/yyyy"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">
              <el-switch v-model="massUpdate.updatePatientType" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
            </el-col>
            <el-col :span="18">
              <el-form-item size="mini" ref="patientTypeFormItem" label="Patient Type">
                <el-select :disabled="!massUpdate.updatePatientType" :default-first-option="true" size="mini" v-model="massUpdate.ascTableMassUpdateFields.patientType" placeholder="Review Type" clearable filterable>
                  <template v-if="!isAsc">
                    <el-option v-for="item in dropdowns.outpatientPatientTypes.list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.inactive"> </el-option>
                  </template>
                  <template v-if="isAsc">
                    <el-option v-for="item in dropdowns.ambulatoryPatientTypes.list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.inactive"> </el-option>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="3">
              <el-switch v-model="massUpdate.updateProvider" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
            </el-col>
            <el-col :span="18">
              <el-form-item size="mini" ref="providerFormItem" label="Provider">
                <providerSelector :disabled="!massUpdate.updateProvider" v-model="massUpdate.ascTableMassUpdateFields.providerId" @selected="clientProvider = $event" :provider="clientProvider"> </providerSelector>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="3">
              <el-switch v-model="massUpdate.updateConsultantNumber" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
            </el-col>
            <el-col :span="18">
              <el-form-item size="mini" ref="consultantNumberFormItem" label="Auditor">
                <el-select :disabled="!massUpdate.updateConsultantNumber" :default-first-option="true" size="mini" v-model="massUpdate.ascTableMassUpdateFields.consultantNumber" placeholder="Auditor" clearable filterable>
                  <el-option v-for="item in dropdowns.consultants.list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.inactive"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">
              <el-switch v-model="massUpdate.updateCoder" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-col>
            <el-col :span="18">
              <el-form-item size="mini" ref="coderFormItem" label="Coder">
                <el-col :span="24" style="padding-left: 0px; padding-right: 0px;">
                  <coderSelector :disabled="!massUpdate.updateCoder" v-model="massUpdate.ascTableMassUpdateFields.coderId" @selected="clientCoder = $event" :clientcoder="clientCoder"> </coderSelector>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">
              <el-switch v-model="massUpdate.updatePayor" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-col>
            <el-col :span="18">
              <el-form-item ref="payorNumberFormItem" size="mini" label="Payor">
                <hia-el-select :disabled="!massUpdate.updatePayor" v-model="massUpdate.ascTableMassUpdateFields.payorNumber" placeholder="Payor" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.payors.list"> </hia-el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="3">
              <el-switch v-model="massUpdate.updateStatus" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-col>
            <el-col :span="18">
              <el-form-item ref="statusFormItem" size="mini" label="Review Status">
                <el-select :disabled="!massUpdate.updateStatus" :default-first-option="true" size="mini" v-model="massUpdate.ascTableMassUpdateFields.reviewStatusId" placeholder="Review Status" clearable filterable>
                  <el-option v-for="item in dropdowns.reviewStatus.list" :key="item.key" :label="item.value" :value="item.key"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="3">
              <el-switch v-model="massUpdate.updateRecordTags" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
            </el-col>
            <el-col :span="13">
              <el-form-item size="mini" ref="recordTagsFormItem" label="Record Tags">
                <el-select class="multiSelect" :disabled="!massUpdate.updateRecordTags" size="mini" v-model="massUpdate.ascTableMassUpdateFields.tagsSelections" filterable multiple placeholder="Tags">
                  <el-option v-for="item in dropdowns.recordTags.list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.inactive"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5" style="margin-left: 10px;">
              <el-row>
                <input :disabled="!massUpdate.updateRecordTags" id="overwriteTagsFalse" type="radio" name="overwrite" value="false" v-model="massUpdate.overwriteTags" />
                Append Tags
              </el-row>
              <el-row> <input :disabled="!massUpdate.updateRecordTags" id="overwriteTagsTrue" type="radio" name="overwrite" value="true" v-model="massUpdate.overwriteTags" /> Overwrite Tags </el-row>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="3">
              <el-switch v-model="massUpdate.updateComments" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
            </el-col>
            <el-col :span="13">
              <el-form-item label="Comments">
                <el-input style="margin-top: 7px;" :disabled="!massUpdate.updateComments" type="textarea" :rows="3" id="comments" v-model="massUpdate.ascTableMassUpdateFields.comments"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" style="margin-left: 10px;">
              <el-row style="margin-top: 7px;">
                <input :disabled="!massUpdate.updateComments" id="icd9" type="radio" name="overwriteComments" value="false" v-model="massUpdate.overwriteComments" />
                Append Comments
              </el-row>
              <el-row> <input :disabled="!massUpdate.updateComments" id="icd10" type="radio" name="overwriteComments" value="true" v-model="massUpdate.overwriteComments" /> Overwrite Comments </el-row>
            </el-col>
          </el-row>
        </el-row>
        <br />
      </el-form>
      <el-button-group style="float: right;">
        <el-button class="hiaButton" plain round type="text" @click="handleClose">Cancel</el-button>
        <el-button class="hiaButton" plain round :disabled="disableConfirmButton" @click="runMassUpdate(massUpdate)">Confirm</el-button>
      </el-button-group>
      <br />
    </el-dialog>
  </div>
</template>

<script>
  import projectSelector from '@/components/controls/projectSelector'
  import { mapActions, mapMutations, mapState } from 'vuex'
  // import axios from '@/utilities/axios-global'
  import coderSelector from '@/components/controls/coderSelector'
  import providerSelector from '@/components/controls/providerSelector'

  export default {
    name: 'OutpatientReviewMassUpdate',
    props: ['selectedRecords', 'selectedClients', 'showMassUpdateDialog'],
    data() {
      return {
        loading: true,
        runningMassUpdate: false,
        form: {
          addCoder: ''
        },
        rules: {
          addCoder: [{ required: true, message: 'Please Set Coder' }]
        },
        addCoderPopOverVisible: false,
        clientCoder: {},
        selectedProject: {},
        clientProvider: {},
        massUpdate: {
          updateClientNumber: false,
          updateDate: false,
          updateReviewLevel: false,
          updatePatientType: false,
          updateConsultantNumber: false,
          updateIandI: false,
          updateProvider: false,
          updateNoUbAvailable: false,
          updateEandM: false,
          updateSandI: false,
          updatePrebill: false,
          updateDocEval: false,
          updateCoder: false,
          updateComments: false,
          updateOutpatientGrouperId: false,
          overwriteComments: false,
          updateRecordTags: false,
          overwriteTags: false,
          updateProject: false,
          updatePayor: false,
          updateIncludeInExit: false,
          updateGrouper: false,
          ascTableIds: [],
          ascTableMassUpdateFields: {
            clientId: '',
            date: '',
            reviewLevel: '',
            patientType: '',
            outpatientGrouperId: '',
            consultantNumber: '',
            ubAbstract: false,
            providerId: null,
            reviewSandI: false,
            reviewIandI: false,
            reviewEandM: false,
            prebill: false,
            docEval: false,
            cmonly: false,
            comments: '',
            projectID: 0,
            payorNumber: '',
            includeInExit: false,
            coderId: null,
            hiaCoderId: null,
            tagsSelections: []
          }
        }
      }
    },
    methods: {
      ...mapActions('outpatientReviews/', ['RUN_OP_MASSUPDATE']),
      ...mapActions('clients/coders/', ['POST_CODER']),
      ...mapActions('dropdowns', ['GET_DROPDOWNS']),
      ...mapMutations('dropdowns/', ['PUSH_NEWCODER']),
      ...mapMutations('dropdowns/', ['SET_DROPDOWNS']),
      handleClose() {
        this.$emit('close')
      },
      async fetchDropdowns() {
        this.loading = true
        await this.GET_DROPDOWNS(['reviewLevels', 'outpatientPatientTypes', 'ambulatoryPatientTypes', 'consultants', 'payors', 'reviewStatus', 'recordTags'])
        this.loading = false
      },
      runMassUpdate(massUpdate) {
        if (this.validate(massUpdate)) {
          this.confirm(massUpdate)
        }
      },
      validate(massUpdate) {
        let valid = true
        if (massUpdate.updateClientNumber && !massUpdate.ascTableMassUpdateFields.clientId) {
          this.$refs.clientNumberFormItem.$el.className += ' is-error'
          valid = false
        }
        if (massUpdate.updateDate) {
          if (!massUpdate.ascTableMassUpdateFields.date) {
            this.$refs.dateFormItem.$el.className += ' is-error'
            valid = false
          }
        }
        if (massUpdate.updateReviewLevel && !massUpdate.ascTableMassUpdateFields.reviewLevel) {
          this.$refs.reviewLevelFormItem.$el.className += ' is-error'
          valid = false
        }
        if (massUpdate.updatePatientType && !massUpdate.ascTableMassUpdateFields.patientType) {
          this.$refs.patientTypeFormItem.$el.className += ' is-error'
          valid = false
        }
        if (massUpdate.updateConsultantNumber && !massUpdate.ascTableMassUpdateFields.consultantNumber) {
          this.$refs.consultantNumberFormItem.$el.className += ' is-error'
          valid = false
        }
        if (massUpdate.updatePayor && !massUpdate.ascTableMassUpdateFields.payorNumber) {
          this.$refs.payorNumberFormItem.$el.className += ' is-error'
          valid = false
        }

        if (massUpdate.updateStatus && !massUpdate.ascTableMassUpdateFields.reviewStatusId) {
          this.$refs.statusFormItem.$el.className += ' is-error'
          valid = false
        }

        if (massUpdate.updateProject && !massUpdate.ascTableMassUpdateFields.projectID) {
          this.$refs.projectFormItem.$el.className += ' is-error'
          valid = false
        }

        if (massUpdate.updateCoder && !massUpdate.ascTableMassUpdateFields.coderId) {
          this.$refs.coderFormItem.$el.className += ' is-error'
          valid = false
        }

        if (massUpdate.updateGrouper && !massUpdate.ascTableMassUpdateFields.outpatientGrouperId) {
          this.$refs.grouperFormItem.$el.className += ' is-error'
          valid = false
        }

        return valid
      },
      addCoderToCoderDropDown() {
        this.$refs.form.validate(valid => {
          if (valid) {
            const payload = {
              name: this.form.addCoder.toUpperCase(),
              clientNumber: this.review.clientNumber
            }
            this.POST_CODER(payload).then(response => {
              this.PUSH_NEWCODER(response)
              this.coderComputed = response.coderId
              this.putInpatientReview('coder')
              this.addCoderPopOverVisible = false
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      async confirm(massUpdate) {
        const updatedFields = []
        if (this.massUpdate.updateClientNumber) {
          updatedFields.push('Facility')
        }
        if (this.massUpdate.updateDate) {
          updatedFields.push('Date')
        }
        if (this.massUpdate.updateReviewLevel) {
          updatedFields.push('Review Level')
        }
        if (this.massUpdate.updatePatientType) {
          updatedFields.push('Review Type')
        }
        if (this.massUpdate.updateOutpatientGrouperId) {
          updatedFields.push('Grouper')
        }
        if (this.massUpdate.updateConsultantNumber) {
          updatedFields.push('Auditor')
        }
        if (this.massUpdate.updateCoder) {
          updatedFields.push('Coder')
        }
        if (this.massUpdate.updateIandI) {
          updatedFields.push('I and I')
        }
        if (this.massUpdate.updateNoUbAvailable) {
          updatedFields.push('No UB Available')
        }
        if (this.massUpdate.updateEandM) {
          updatedFields.push('E and M')
        }
        if (this.massUpdate.updateSandI) {
          updatedFields.push('S and I')
        }
        if (this.massUpdate.prebill) {
          updatedFields.push('Prebill')
        }
        if (this.massUpdate.updateDocEval) {
          updatedFields.push('DocEval')
        }
        if (this.massUpdate.updateComments) {
          updatedFields.push('Comments')
        }
        if (this.massUpdate.updateProject) {
          updatedFields.push('Project')
        }
        if (this.massUpdate.updatePayor) {
          updatedFields.push('Payor')
        }
        if (this.massUpdate.updateIncludeInExit) {
          updatedFields.push('Include In Exit')
        }
        if (this.massUpdate.updateStatus) {
          updatedFields.push('Review Status')
        }

        if (this.massUpdate.updateRecordTags) {
          updatedFields.push('Record Tags')
        }

        if (this.massUpdate.updateProvider) {
          updatedFields.push('Provider')
        }

        if (this.massUpdate.updateGrouper) {
          updatedFields.push('Grouper')
        }

        const fields = updatedFields.join(', ').replace(/,(?!.*,)/gim, ' and')
        await this.$confirm('This will update ' + fields + ' for ' + this.selectedRecords.length + ' records. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          massUpdate.ascTableIds = this.selectedRecords
          this.runningMassUpdate = true
          await this.RUN_OP_MASSUPDATE(massUpdate)
          this.runningMassUpdate = false
          this.$emit('massupdate')
          this.$message({
            message: 'Mass Update Successful',
            type: 'success'
          })
        } catch (err) {
          this.runningMassUpdate = false
          this.$message({
            message: 'Mass Update Error',
            type: 'error'
          })
        }
      }
    },
    created: async function() {
      await this.fetchDropdowns()
    },
    computed: {
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('managedLists/projects/', ['project']),
      coderComputed: {
        get: function() {
          if (this.massUpdate.ascTableMassUpdateFields.hiaCoderId) {
            return this.massUpdate.ascTableMassUpdateFields.hiaCoderId
          }
          return this.massUpdate.ascTableMassUpdateFields.coderId
        },
        set: function(val) {
          if (!val) {
            this.massUpdate.ascTableMassUpdateFields.hiaCoderId = null
            this.massUpdate.ascTableMassUpdateFields.coderId = null
          }

          if (typeof val === 'string') {
            this.massUpdate.ascTableMassUpdateFields.hiaCoderId = val
            this.massUpdate.ascTableMassUpdateFields.coderId = null
          } else {
            this.massUpdate.ascTableMassUpdateFields.hiaCoderId = null
            this.massUpdate.ascTableMassUpdateFields.coderId = val
          }
        }
      },
      disableConfirmButton: function() {
        if (!this.massUpdate.updateClientNumber && !this.massUpdate.updateDate && !this.massUpdate.updateReviewLevel && !this.massUpdate.updatePatientType && !this.massUpdate.updateOutpatientGrouperId && !this.massUpdate.updateConsultantNumber && !this.massUpdate.updateCoder && !this.massUpdate.updateIandI && !this.massUpdate.updateNoUbAvailable && !this.massUpdate.updateEandM && !this.massUpdate.updateSandI && !this.massUpdate.updateDocEval && !this.massUpdate.updateComments && !this.massUpdate.updateProject && !this.massUpdate.updatePayor && !this.massUpdate.updatePrebill && !this.massUpdate.updateIncludeInExit && !this.massUpdate.updateStatus && !this.massUpdate.updateProvider && !this.massUpdate.updateGrouper && !this.massUpdate.updateRecordTags) {
          return true
        }
        return false
      },
      isAsc() {
        return this.project.outpatientGrouperId === 2
      }
    },
    components: {
      projectSelector,
      coderSelector,
      providerSelector
    }
  }
</script>
