<template>
  <!--this is just a simple wrapper that runs through the coder worksheet endpoint -->
  <div class="worksheet" style="max-width: 1000px; margin: auto;">
    <div style="margin-top: 50px;" v-if="loading" v-loading="loading" element-loading-spinner="atom-audit-loader">
    </div>
    <div v-if="!loading">
      <el-button class="pull-right print-hidden" round style="margin-top: 0px;" icon="mdi mdi-details" size="mini" @click="saveWorksheet">Download</el-button>
      <div v-for="(worksheet, index) in worksheets" :key="worksheet.patientNumber" class="page-break" style="margin-bottom: 50px;">
        <TenantReportHeader v-if="index === 0"></TenantReportHeader>
        <h1 class="header-row">Professional Fee Review Worksheet</h1>
        <ProWorksheet :worksheet="worksheet"></ProWorksheet>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { format, parseISO } from 'date-fns'
  import ProWorksheet from '@/views/reviews/pro/worksheets/ProWorksheet.vue'
  import TenantReportHeader from '@/views/reports/printable/TenantReportHeader.vue'

  import axios from '@/utilities/axios-global'
  export default {
    name: 'CoderProWorksheetReport',
    props: ['proRecordIds'],
    data() {
      return {
        loading: true,
        format: format,
        parseISO: parseISO,
        dropdownsNeeded: ['RecommendationTypes', 'RecommendationRecordTypes', 'RecommendationCodeGroups', 'clients']
      }
    },
    components: {
      ProWorksheet,
      TenantReportHeader
    },
    methods: {
      ...mapActions('managedLists/tenantSetup/', ['GET_TENANT_SETUP']),
      saveWorksheet() {
        window.print()
      }
    },
    mounted: async function () {
      const proRecordId = this.$route.params.proRecordId
      const response = await Promise.all([axios.get(this.baseApiUrl + `/coder/pro/worksheet/${proRecordId}`), this.GET_TENANT_SETUP()])
      this.worksheets = [response[0].data]
      this.loading = false
    },
    computed: {
      ...mapState('user/', [
        'baseApiUrl'
      ])
    }
  }
</script>

<style scoped>
  @media print {
    .page-break {
      display: block;
      page-break-after: always;
    }

    .print-hidden {
      display: none;
    }
  }
</style>