<template>
  <div v-loading="rebuttalLoading" element-loading-spinner="atom-audit-loader">
    <transition-group name="fade">
      <div v-if="rebuttal" style="margin: 0px 0px 0px 0px; min-height: 250px;" key="1">
        <div style="margin-left: 10px;">
          <el-form ref="form" id="form" label-position="left" label-width="200px" size="mini" :key="1" style="margin-top: 0px;">
            <el-form-item label="Response Date">
              <div style="margin: 6px 0 0 15px;">{{ formatDate(rebuttal.rebuttalDate) }}</div>
            </el-form-item>
            <el-form-item v-if="worksheet.auditResult !== 'No Change'" label="Coder agrees with changes">
              <el-col style="margin-top: 3px;"> <input :disabled="readOnly()" type="radio" name="codes" :value="true" v-model="rebuttal.agreeWithCodes" @change="putRebuttal" /> Yes <input :disabled="readOnly()" type="radio" name="codes" :value="false" v-model="rebuttal.agreeWithCodes" @change="putRebuttal" /> No </el-col>
            </el-form-item>
            <el-form-item v-if="worksheet.auditResult !== 'No Change'" label="Auditor Response Required">
              <el-col style="margin-top: 3px;"> <input :disabled="(rebuttal.agreeWithCodes ? false : true) || readOnly()" type="radio" name="auditor" :value="true" v-model="rebuttal.auditorResponseRequired" @change="putRebuttal" /> Yes <input :disabled="(rebuttal.agreeWithCodes ? false : true) || readOnly()" type="radio" name="auditor" :value="false" v-model="rebuttal.auditorResponseRequired" @change="putRebuttal" /> No </el-col>
            </el-form-item>
          </el-form>

          <div style="margin-top: 20px; font-size: 13px;" v-for="comment in rebuttal.rebuttalComments.filter(x => x.commentId)" :key="comment.commentId">
            {{ comment.user.fullName }} on <i>{{ format(parseISO(comment.createdDate), 'M/d/yyyy hh:mm a') }}</i> <span style="font-weight: bold;" :title="format(parseISO(comment.modifiedDate), 'M/d/yyyy hh:mm a')" v-if="comment.edited"><small>edited</small></span>
            <HiaRichText @change="putComment(comment)" :editable="user.auth0Id === comment.user.auth0Id" v-model="comment.comment" :title="`${comment.user.fullName} on ${format(parseISO(comment.createdDate), 'M/d/yyyy hh:mm a')}`"> </HiaRichText>
          </div>

          <div style="margin-top: 15px;">
            <Tinymce :disabled="readOnly()" placeholder="Add comment" id="newCommentExistingRebuttal" :height="250" v-model="newComment" @dirty="$emit('dirty', true)"> </Tinymce>
          </div>
        </div>
      </div>
    </transition-group>
    <div style="margin-top: 20px;">
      <el-button :disabled="readOnly()" v-if="!newMode && !rebuttal" class="hiaButton" plain round @click="addRebuttal(true)" size="mini">Create Response</el-button>

      <el-popover ref="savePopover" style="word-break: normal;margin-left: 5px;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" :content="savePopoverContent" v-model="showSavePopover" @after-enter="handleHidePopOver('savePopover')">
        <el-button icon="el-icon-check" :disabled="readOnly()" v-if="worksheet.auditResult === 'No Change' && newMode" slot="reference" class="hiaButton" plain round size="mini" @click="postRebuttal">Acknowledge No Change</el-button>
        <el-button icon="el-icon-plus" :disabled="readOnly()" v-if="newMode && worksheet.auditResult !== 'No Change'" slot="reference" class="hiaButton" plain round size="mini" @click="postRebuttal">Add Response</el-button>
        <el-button icon="el-icon-plus" :disabled="readOnly()" v-if="rebuttal && rebuttal.rebuttalId" slot="reference" class="hiaButton" plain round size="mini" @click="addComment">Add Comment </el-button>
      </el-popover>
    </div>
  </div>
</template>

<script>
  import Tinymce from '@/components/controls/Tinymce'
  import HiaRichText from '@/components/controls/HiaRichText'
  import dateHelper from '@/mixins/date-helper'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import { format, parseISO } from 'date-fns'
  import { mapActions, mapState, mapMutations } from 'vuex'

  export default {
    name: 'coderInpatientResponses',
    mixins: [dateHelper, HiaAuthorization],
    props: ['worksheet', 'drgTableId'],
    data() {
      return {
        format: format,
        parseISO: parseISO,
        disabled: true,
        newComment: '',
        loading: false,
        newMode: false,
        rebuttalLoading: false,
        showSavePopover: false,
        savePopoverContent: null
      }
    },
    components: {
      Tinymce,
      HiaRichText
    },
    methods: {
      ...mapActions('rebuttals/', ['POST_REBUTTAL', 'DELETE_REBUTTAL', 'PUT_REBUTTAL', 'POST_REBUTTAL_COMMENT', 'PUT_REBUTTAL_COMMENT']),
      ...mapMutations('rebuttals/', ['SET_REBUTTAL']),
      async putComment(comment) {
        try {
          this.rebuttalLoading = true
          await this.PUT_REBUTTAL_COMMENT(comment)
          this.rebuttalLoading = false
        } catch (e) {
          this.rebuttalLoading = false
          this.$message({
            message: 'Error Editing Comment',
            type: 'error'
          })
          console.log(e)
        }
      },
      async addComment() {
        if (!this.newComment) {
          this.savePopoverContent = 'Comment field is empty'
          this.showSavePopover = true
          return
        }

        try {
          this.rebuttalLoading = true
          await this.POST_REBUTTAL_COMMENT({
            rebuttalId: this.rebuttal.rebuttalId,
            comment: this.newComment
          })
          this.newComment = ''
          this.rebuttalLoading = false
          this.$emit('dirty', false)
        } catch (e) {
          this.$message({
            message: 'Error Adding Comment',
            type: 'error'
          })
          console.log(e)
          this.rebuttalLoading = false
        }
      },
      async postRebuttal() {
        if (this.rebuttal.agreeWithCodes === null && this.worksheet.auditResult !== 'No Change') {
          this.savePopoverContent = "Please select above whether you agree or disagree with the auditor's changes"
          this.showSavePopover = true
          return
        }

        if (this.rebuttal.agreeWithCodes === false && !this.newComment) {
          this.savePopoverContent = 'When disagreeing with auditor, please use a comment to document your rationale for disagreement'
          this.showSavePopover = true
          return
        }

        // if (this.rebuttal.agreeWithCodes === null) {
        //   this.showSavePopover = true
        //   return
        // }

        // const message = this.rebuttal.agreeWithCodes ? 'Add response stating that you agree with the reviewer\'s audit?' : 'Add response stating that you disagree with the reviewer\'s audit?'
        // await this.$confirm(message, 'Warning', {
        //   confirmButtonText: 'OK',
        //   cancelButtonText: 'Cancel',
        //   type: 'warning'
        // })

        try {
          this.rebuttal.auditorResponseRequired = false
          if (!this.rebuttal.agreeWithCodes) {
            this.rebuttal.auditorResponseRequired = true
          }
          const rebuttalComments = this.newComment ? [{ comment: this.newComment, rebuttalId: 0 }] : []
          this.rebuttal.rebuttalComments = rebuttalComments
          this.rebuttalLoading = true
          await this.POST_REBUTTAL(this.rebuttal)
          await this.$emit('GET_IP_WORKSHEET', this.$route.params.drgTableId)
          this.$message({
            message: 'Coder Response Added Successfully',
            type: 'success'
          })
          this.newMode = false
          this.newComment = ''
          this.rebuttalLoading = false
          this.$emit('dirty', false)
        } catch (err) {
          this.$message({
            message: 'Error Adding Coder Response',
            type: 'error'
          })
        }
      },
      async putRebuttal() {
        if (!this.rebuttal.agreeWithCodes) {
          this.rebuttal.auditorResponseRequired = true
        }

        if (!this.rebuttal.rebuttalId) {
          this.$emit('dirty', true)
          return
        }

        try {
          await this.PUT_REBUTTAL(this.rebuttal)

          this.$message({
            message: 'Coder Response Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.$message({
            message: 'Error Updating Coder Response',
            type: 'error'
          })
        }
      },
      addRebuttal(agree) {
        this.SET_REBUTTAL({
          agreeWithCodes: null,
          auditorResponseRequired: null,
          rebuttalDate: new Date().toISOString(),
          drgTableId: this.drgTableId,
          rebuttalComments: []
        })

        this.newMode = true
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = e => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      }
    },
    created: function() {
      if (!this.rebuttal.rebuttalId) {
        this.addRebuttal(true)
      }
    },
    computed: {
      ...mapState('rebuttals/', ['rebuttal']),
      ...mapState('user/', ['user']),
      ...mapState('coder/', ['responseRow', 'responseDRG', 'responsePatStat', 'responsePOO'])
    },
    watch: {
      responseDRG(val) {
        if (val.originalDrg && val.recommendedDrg) {
          this.newComment += `<p><b>Original DRG ${val.originalDrg} changed to ${val.recommendedDrg}</b></p>`
        } else {
          this.newComment += `<p><b>DRG changed to ${val.recommendedDrg}</b></p>`
        }
      },
      responsePatStat(val) {
        if (val.originalPatStat && val.recommendedPatStat) {
          this.newComment += `<p><b>Original Patient Status ${val.originalPatStat} changed to ${val.recommendedPatStat}</b></p>`
        } else {
          this.newComment += `<p><b>Patient Status changed to ${val.recommendedPatStat}</b></p>`
        }
      },
      responsePOO(val) {
        if (val.originalPoo && val.recommendedPoo) {
          this.newComment += `<p><b>Original Point of Origin ${val.originalPoo} changed to ${val.recommendedPoo}</b></p>`
        } else {
          this.newComment += `<p><b>Point of Origin changed to ${val.recommendedPoo}</b></p>`
        }
      },
      responseRow(val) {
        if (val.reasonForChange === 'Resequence PDx') {
          this.newComment += `<p><b>Principal Dx resequenced to ${val.recommendedDx}</b></p>`
          return
        }

        if (val.reasonForChange === 'Resequence SDx') {
          this.newComment += `<p><b>Secondary Dx resequenced to ${val.recommendedDx}</b></p>`
          return
        }

        if (val.originalDx && val.recommendedDx && !val.originalDeleted) {
          this.newComment += `<p><b>Dx ${val.originalDx} changed to ${val.recommendedDx}</b></p>`
        }
        if (!val.originalDx && val.recommendedDx) {
          this.newComment += `<p><b>Dx ${val.recommendedDx} added</b></p>`
        }
        if (val.originalDeleted && val.originalDx) {
          this.newComment += `<p><b>Dx ${val.originalDx} deleted</b></p>`
        }

        if (val.recommendedPOA) {
          this.newComment += `<p><b>Dx POA ${val.recommendedPOA} recommended on ${val.recomendedDx || val.originalDx}</b></p>`
        }

        if (val.originalPx && val.recommendedPx && !val.originalDeleted) {
          this.newComment += `<p><b>Px ${val.originalPx} changed to ${val.recommendedPx}</b></p>`
        }

        if (!val.originalPx && val.recommendedPx) {
          this.newComment += `<p><b>Px ${val.recommendedPx} added</b></p>`
        }
        if (val.originalDeleted && val.originalPx) {
          this.newComment += `<p><b>Px ${val.originalPx} deleted</b></p>`
        }
      }
    }
  }
</script>

<style scoped>
  .richText {
    border: 1px solid #dcdfe6;
    background: #e5eef4;
    border-radius: 4px;
    padding: 5px;
    margin: 10px 0 20px 0px;
    min-height: 30px;
    font-size: 14px;
    overflow-x: auto;
  }

  .richText img {
    width: 100%;
    /* or any custom size */
    height: 100%;
    object-fit: contain;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 1s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    height: 0px;
  }
</style>
