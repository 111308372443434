<template>
  <div>
    <table class="frequency-table" border="0" cellpadding="0" cellspacing="0" border-spacing="0">
      <thead>
        <tr>
          <th style="word-break: auto-phrase;">EM Code</th>
          <th style="text-align: right; word-break: auto-phrase;" v-for="col in tableColumns" :key="col">
            {{ col }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style="word-break: auto-phrase;" v-for="(row, index) in tableRows" :key="index">
          <td>{{ row }}</td>
          <td style="text-align: right;" v-for="(row, z) in tableData[index]" :key="z">
            {{ z % 2 === 0 ? row : `${row.toFixed(2)}%` }}
          </td>
        </tr>
        <tr class="totalsRow">
          <td><i>total</i></td>
          <td style="text-align: right;" v-for="(sum, y) in getTotalSum" :key="y">
            {{ y % 2 === 0 ? sum : `${sum.toFixed(2)}%` }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'FrequencyTable',
    props: ['seriesData', 'medicareCompare', 'specialtyCompare', 'providerSpecialtiesCompare'],
    data: function() {
      return {}
    },
    methods: {
      // getTableData() {
      //   if (this.seriesData) {
      //     return this.seriesData.labels.map((label, i) => {
      //       return {
      //         label: label,
      //         frequency: this.seriesData.frequencies[i],
      //         percentage: this.seriesData.percentages[i]
      //       }
      //     })
      //   }
      //   return []
      // }
      // getTotalSum() {
      //   const columnSums = new Array(this.tableData[0].length).fill(0)
      //   // Loop through each row
      //   for (const row of this.tableData) {
      //     // Loop through each column in the row
      //     row.forEach((value, index) => {
      //       columnSums[index] += value
      //     })
      //   }
      //   console.log(columnSums)
      //   return columnSums
      //   // const total = this.tableData.reduce((sum, item) => sum + item, 0)
      //   // return total
      // }
    },
    computed: {
      tableData() {
        const allData = []

        if (this.tableRows) {
          // get series data
          const category = this.seriesData.find(f => f.seriesType === 'category') || {}
          const medicare = this.seriesData.find(f => f.seriesType === 'medicare') || {}
          const mySpecialty = this.seriesData.find(f => f.seriesType === 'mySpecialty') || {}
          const specialties = this.seriesData.find(f => f.seriesType === 'specialties') || {}
          // loop over labels; note the code assumes each datapoint is in the correct order of label already
          // if not, matching logic will be required
          this.tableRows.forEach((rowLabel, i) => {
            const row = []
            // category data
            row.push((category.frequencies ?? [])[i] ?? 0)
            row.push((category.percentages ?? [])[i] ?? 0)
            if (Object.keys(medicare).length && this.medicareCompare) {
              row.push((medicare.frequencies ?? [])[i] ?? 0)
              row.push((medicare.percentages ?? [])[i] ?? 0)
            }

            if (Object.keys(mySpecialty).length && this.specialtyCompare) {
              row.push((mySpecialty.frequencies ?? [])[i] ?? 0)
              row.push((mySpecialty.percentages ?? [])[i] ?? 0)
            }

            if (Object.keys(specialties).length && this.providerSpecialtiesCompare) {
              row.push((specialties.frequencies ?? [])[i] ?? 0)
              row.push((specialties.percentages ?? [])[i] ?? 0)
            }
            allData.push(row)
          })
        }
        return allData
      },
      tableRows() {
        return this.seriesData.find(f => f.seriesType === 'category').labels || []
      },
      tableColumns() {
        const allColumns = ['Freq.', '%']
        const medicare = this.seriesData.find(f => f.seriesType === 'medicare') || {}
        const mySpecialty = this.seriesData.find(f => f.seriesType === 'mySpecialty') || {}
        const specialties = this.seriesData.find(f => f.seriesType === 'specialties') || {}

        if (Object.keys(medicare).length && this.medicareCompare) {
          allColumns.push('Medicare Freq.')
          allColumns.push('Medicare %')
        }

        if (Object.keys(mySpecialty).length && this.specialtyCompare) {
          allColumns.push('My Specialty Freq.')
          allColumns.push('My Specialty %')
        }

        if (Object.keys(specialties).length && this.providerSpecialtiesCompare) {
          allColumns.push('Specialties Freq.')
          allColumns.push('Specialties %')
        }

        return allColumns
      },
      getTotalSum() {
        const columnSums = this.tableData.length ? new Array(this.tableData[0].length).fill(0) : []

        // Loop through each row
        for (const row of this.tableData) {
          // Loop through each column in the row
          row.forEach((value, index) => {
            columnSums[index] += value
          })
        }
        return columnSums
      }
    }
  }
</script>

<style scoped>
  /* .frequency-table {
    width: 100%;
  }
  .frequency-table thead {
    background-color: #f2f2f2;
  }
  .frequency-table thead tr {
    background-color: #f2f2f2;
  }

  .frequency-table thead tr th {
    background-color: #f2f2f2 !important;
  }
  .frequency-table tbody tr:nth-child(even) {
    background-color: #f7f7f7;
  }

  .frequency-table tbody tr:hover {
    background-color: #e6e9ff;
  }

  .frequency-table tbody tr td {
    border: 1px solid #cccccc;
  }
  .frequency-table tbody tr:nth-child(even):hover {
    background-color: #e6e9ff;
  }

  .frequency-table td {
    border-bottom: unset;
  } */
  /* General Table Styling */
  .frequency-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }

  /* Table Header Styling */
  .frequency-table th {
    background-color: #ffffff !important; /* Header background color */
    color: #333;
    text-align: left;
    padding: 12px;
    font-weight: bold;
    border-bottom: 0px solid #ddd;
  }

  /* Table Body Styling */
  .frequency-table td {
    padding: 10px;
    text-align: right; /* Align numbers to the right */
    color: #333;
    border-top: 1px solid #ebeef5 !important;
    border-bottom: 1px solid #ebeef5 !important;
  }

  /* Alternate Row Colors */
  .frequency-table tr:nth-child(odd) {
    background-color: #f2f2f2;
  }

  .frequency-table tr:nth-child(even) {
    background-color: #f7f7f7;
  }

  /* Hover Effect for Rows */
  .frequency-table tr:hover {
    background-color: #e6e9ff;
  }

  /* Add subtle rounded corners to the table */
  .frequency-table {
    border-radius: 8px;
    overflow: hidden;
  }

  .totalsRow td {
    background-color: #ffffff;
    word-break: auto-phrase;
  }
</style>
