<template>
  <div>
    <v-client-table key="pxGrid" class="hiaTable" ref="pxGrid" id="pxGrid" :data="worksheet.pxs" :columns="columns" :options="options" uniqueKey="pxId">

      <template slot="originalPx" slot-scope="props">
        <div>
          {{props.row.originalPx}}
        </div>
      </template>

      <template slot="originalDescription" slot-scope="props">
        <div style="text-transform: lowercase;">{{props.row.originalDescription}} <span v-if="props.row.originalGrouperFlags" :title="getGrouperflagsTitle(props.row.originalGrouperFlags)" class="grouperFlags">({{props.row.originalGrouperFlags}})</span></div>
      </template>

      <template slot="recommendedPx" slot-scope="props">
        <div>
          {{props.row.originalDeleted ? 'DEL' : props.row.recommendedPx}}
        </div>
      </template>

      <template slot="recommendedPoa" slot-scope="props">
        <div style="text-align: center;">{{ props.row.recommendedPOA }}</div>
      </template>

      <template slot="recommendedDesc" slot-scope="props">
        <div style="text-transform: lowercase;">{{props.row.originalDeleted ? 'Delete Code' : props.row.recommendedDesc}} <span :title="getGrouperflagsTitle(props.row.recommendedGrouperFlags)" v-if="((props.row.recommendedPx && props.row.recommendedGrouperFlags) || (props.row.recommendedGrouperFlags && props.row.recommendedGrouperFlags !== props.row.originalGrouperFlags))" class="grouperFlags">({{props.row.recommendedGrouperFlags}})</span></div>

      </template>

      <template slot="reasonForChange" slot-scope="props">

        {{props.row.reasonForChange}}
      </template>

      <template slot="response" slot-scope="props">
        <el-button :disabled="readOnly()" style="margin-right: 5px;" size="mini" v-if="props.row.recommendedPx || props.row.originalDeleted || props.row.recommendedPOA" type="text" @click="addResponse(props.row)">Respond
        </el-button>
      </template>

    </v-client-table>

  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import dateHelper from '@/mixins/date-helper'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'coderInpatientPxs',
    mixins: [dateHelper, HiaAuthorization],
    props: ['worksheet'],
    data() {
      return {
        format: format,
        parseISO: parseISO,
        disabled: true,
        loading: false,
        columns: ['originalPx', 'originalDescription', 'recommendedPx', 'recommendedDesc', 'reasonForChange', 'response'],
        options: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          queue: [],
          networkFunction: null,
          perPage: 100,
          rowClassCallback: row => {
            if (row.recommendedPx || row.originalDeleted || row.newPrimary || row.recommendedPOA) {
              return 'highlight'
            }
            return ''
          },
          columnsClasses: {
            originalPx: 'codeCol',
            originalPoa: 'fiftyPx',
            originalDescription: 'twentyFivePercent',
            recommendedPx: 'codeCol',
            recommendedPoa: 'fiftyPx',
            recommendedDesc: 'twentyFivePercent',
            reasonForChange: 'reasonForChangeCol'
          },
          headings: {
            originalPx: 'Orig. Px',
            originalPoa: 'POA',
            recommendedPx: 'Rec. Px',
            recommendedPoa: 'POA',
            originalDescription: 'Desc.',
            recommendedDesc: 'Desc.',
            reasonForChange: 'Reason For Change',
            response: ''
          }
        }
      }
    },
    methods: {
      ...mapMutations('coder/', ['SET_RESPONSE_ROW']),
      getGrouperflagsTitle(flagList) {
        if (!flagList) {
          return
        }

        const flagMap = {
          AD: 'Affects DRG',
          CC: 'Complication/Comorbidity',
          HC: 'Hospital-Acquired Condition',
          MC: 'Complication/Comorbidity',
          R: 'Affects Risk of Mortality',
          S: 'Affects Severity of Illness',
          AP: 'Affects Patient Safety Indicators',
          EP: 'Excludes Patient Safety Indicators',
          AQ: 'Affects Quality Measures',
          EQ: 'Excludes Quality Measures'
        }

        const flags = flagList.split(',')
        const title = []
        flags.forEach(x => {
          title.push(`${x}: ${flagMap[x]}`)
        })

        return title.join('\n')
      },
      addResponse(row) {
        this.SET_RESPONSE_ROW(row)
      }
    }
  }
</script>

<style scoped>
.grouperFlags {
  font-style: italic;
  text-transform: uppercase;
}

>>> .twentyFivePercent {
  min-width: 25%;
}

>>> .reasonForChangeCol {
  min-width: 120px;
}

>>> .highlight {
  /* background-color: rgba(255, 196, 12, 0.1) !important; */
  font-weight: bold;
}

>>> .highlight {
  border-left: 3px solid #f56c6c;
}

/* >>>.highlight:hover {
    background-color: rgba(255, 196, 12, 0.2) !important;
  } */

/* >>>.codeCol {
    min-width: 80px;
  } */

>>> td {
  font-size: 13px !important;
}
</style>
