<template>
  <div id="projectLists">
    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">My Projects</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/my-projects' }">My Projects</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <div v-loading="loading" v-if="loading" element-loading-spinner="atom-audit-loader"></div>
    <el-card v-if="!loading">
      <div>
        <el-row>
          <!-- <el-col :lg="15" :md="12" :sm="10">
            <div>
              <el-button :disabled="!projectManagement()" class="hiaButton" plain round size="mini" @click="triggerShowAddProjectForm" icon="el-icon-plus">Add New Project</el-button>
            </div>
          </el-col> -->
          <el-col :lg="24" :md="24" :sm="24">
            <div style="display: flex" class="pull-right" v-if="activeName !== 'calendar'">
              <template>
                <div style="margin-right: 5px;">
                  <i title="Select advanced view type" class="mdi mdi-filter-variant filterIcon" style="font-size: 18px;"></i>
                </div>
                <div style="margin-right: 5px; width: 200px;">
                  <el-select :value="selectedView" @change="setProjectView($event, selectedCodeType)" placeholder="Select Advanced View Type">
                    <el-option v-for="option in getAdvancedViewOptions()" :key="option" :label="option" :value="option"> </el-option>
                  </el-select>
                </div>
                <!-- <div v-if="selectedView !== 'All Project Types' && getProcesses().length > 1" style="margin-right: 5px; width: 200px;">
                  <el-select v-model="selectedDisplayProcess" @change="setSelectedProcess" :clearable="false" placeholder="Select Process">
                    <el-option v-for="option in getProcesses()" :key="option.key" :label="option.value" :value="option.key"> </el-option>
                  </el-select>
                </div> -->
                <div v-if="['Outpatient', 'Ambulatory', 'Professional Fee'].some(x => x === selectedView)" style="margin-right: 5px; width: 200px;">
                  <el-select v-model="selectedCodeType" @change="setProjectView(selectedView, $event)" placeholder="Select Code Type">
                    <el-option v-for="option in getCodeTypes()" :key="option.key" :label="option.value" :value="option.key"> </el-option>
                  </el-select>
                </div>
              </template>
              <div style="display: grid; grid-template-columns: auto auto">
                <el-date-picker class="hiaRangePicker" size="mini" format="MM/dd/yyyy" value-format="MM/dd/yyyy" v-model="localdateRange" type="daterange" range-separator="To" start-placeholder="Start date" end-placeholder="End date" @change="handleCriteriaChange" style="z-index: 1;" :picker-options="dateRangePickerConfig" clearable> </el-date-picker>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-tabs class="projectListsTab" v-model="activeName">
        <el-tab-pane label="List View" name="list">
          <el-row :span="24" style="margin-top: 20px;">
            <el-col :span="24">
              <coderReviewList v-if="selectedView === 'All Project Types'"></coderReviewList>
              <coderProAdvancedView v-if="selectedView === 'Professional Fee'" :selectedCodeType="selectedCodeType" :selectedProcess="selectedProcess"></coderProAdvancedView>
              <coderInpatientAdvancedView v-if="selectedView === 'Inpatient'" :selectedProcess="selectedProcess"></coderInpatientAdvancedView>
              <coderOutpatientAdvancedView v-if="selectedView === 'Outpatient'" :selectedCodeType="selectedCodeType" :selectedProcess="selectedProcess"></coderOutpatientAdvancedView>
              <coderAmbulatoryAdvancedView v-if="selectedView === 'Ambulatory'" :selectedCodeType="selectedCodeType" :selectedProcess="selectedProcess"></coderAmbulatoryAdvancedView>
            </el-col>
          </el-row>
        </el-tab-pane>

        <!-- <el-tab-pane label="Calendar View" name="calendar" lazy>
          <projectCalendar :activeTab="activeName" :dateRange="localdateRange"></projectCalendar>
        </el-tab-pane> -->
      </el-tabs>
    </el-card>
    <div id="dialogs">
      <!-- <el-dialog title="Create New Project" :visible.sync="showAddProjectForm">
        <addProject v-if="showAddProjectForm"></addProject>
      </el-dialog> -->
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
  import { createHelpers } from 'vuex-map-fields'
  // import addProject from '@/views/projects/addProject'
  import coderReviewList from '@/views/coder/coderReviewList'
  import coderProAdvancedView from '@/views/coder/coderProAdvancedView'
  import coderInpatientAdvancedView from '@/views/coder/coderInpatientAdvancedView'
  import coderOutpatientAdvancedView from '@/views/coder/coderOutpatientAdvancedView'
  import coderAmbulatoryAdvancedView from '@/views/coder/coderAmbulatoryAdvancedView'
  import dateHelper from '@/mixins/date-helper'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  // import projectCalendar from '@/views/projects/projectCalendar'

  const { mapFields } = createHelpers({
    getterType: 'managedLists/projects/getField',
    mutationType: 'managedLists/projects/updateField'
  })

  export default {
    name: 'coderProjectList',
    mixins: [dateHelper, HiaAuthorization],
    data() {
      return {
        activeName: 'list',
        selectedView: 'All Project Types',
        selectedCodeType: 'All Code Types',
        selectedDisplayProcess: 'All Processes',
        selectedProcess: null,
        loading: false,
        dropdownsNeeded: ['codeTypes', 'projectStatus', 'projectProcesses'],
        localdateRange: []
      }
    },
    methods: {
      ...mapActions('managedLists/projects/', ['LOAD_PROJECT_LIST', 'LOAD_PRO_ADVANCED_LIST', 'LOAD_IP_ADVANCED_LIST', 'LOAD_OP_ADVANCED_LIST']),
      ...mapMutations('managedLists/projects/', ['SET_SHOW_ADD_PROJECT_FORM']),
      ...mapMutations('globals/', ['SET_DATERANGE']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      async initialize() {
        try {
          this.loading = true
          await Promise.all([this.GET_DROPDOWNS(this.dropdownsNeeded), this.setProjectView(this.selectedView, this.selectedCodeType)])
          this.setSelectedProcess()
          this.loading = false
        } catch (e) {
          // show error message
          this.loading = false
          console.log(e)
        }
      },
      setSelectedProcess() {
        if (this.selectedDisplayProcess === 'All Processes') {
          this.selectedProcess = null
        } else {
          const listRow = this.dropdowns.projectProcesses.list.find(x => x.value === this.selectedDisplayProcess)
          if (listRow) {
            this.selectedProcess = listRow.key
          }
        }
        this.setLocalStorageProfile()
      },
      async setProjectView(view, codeType) {
        try {
          switch (view) {
            case 'All Project Types':
              await this.LOAD_PROJECT_LIST({ dateRange: this.GET_DATERANGE(), isCoding: true })
              this.selectedView = view
              break
            case 'Professional Fee':
              await this.LOAD_PRO_ADVANCED_LIST({ dateRange: this.GET_DATERANGE(), codeType: codeType, isCoding: true })
              // need to destroy and readd the table as we have dynamic columns for project tasks
              this.selectedView = null
              await this.$nextTick
              this.selectedView = 'Professional Fee'
              break
            case 'Outpatient':
              await this.LOAD_OP_ADVANCED_LIST({ dateRange: this.GET_DATERANGE(), codeType: codeType, opProjectType: 1003, isCoding: true })
              this.selectedView = null
              await this.$nextTick
              this.selectedView = 'Outpatient'
              break
            case 'Ambulatory':
              await this.LOAD_OP_ADVANCED_LIST({ dateRange: this.GET_DATERANGE(), codeType: codeType, opProjectType: 1004, isCoding: true })
              this.selectedView = null
              await this.$nextTick
              this.selectedView = 'Ambulatory'
              break
            case 'Inpatient':
              await this.LOAD_IP_ADVANCED_LIST({ dateRange: this.GET_DATERANGE(), isCoding: true })
              this.selectedView = null
              await this.$nextTick
              this.selectedView = 'Inpatient'
              break
          }
          this.setLocalStorageProfile()
          this.loading = false
        } catch (e) {
          console.log(e)
          this.$message({
            message: 'Error Getting Project List',
            type: 'error'
          })
        }
      },
      getAdvancedViewOptions() {
        const options = ['All Project Types']
        options.push('Inpatient')
        options.push('Outpatient')
        options.push('Professional Fee')
        options.push('Ambulatory')
        return options
      },
      getCodeTypes() {
        const list = this.dropdowns.codeTypes.list.map(x => {
          return {
            key: x.value,
            value: x.value
          }
        })
        list.unshift({ key: 'All Code Types', value: 'All Code Types' })

        return list
      },
      getProcesses() {
        const list = this.dropdowns.projectProcesses.list.map(x => {
          return {
            key: x.value,
            value: x.value
          }
        })
        list.unshift({ key: 'All Processes', value: 'All Processes' })

        return list
      },
      setLocalStorageProfile() {
        const profileStr = window.localStorage.getItem('atomAuditProfile')
        const profile = JSON.parse(profileStr) || {}
        profile.defaultProjectList = this.selectedView
        profile.defaultCodeType = this.selectedCodeType
        profile.defaultProcess = this.selectedDisplayProcess
        window.localStorage.setItem('atomAuditProfile', JSON.stringify(profile))
      },
      triggerShowAddProjectForm() {
        this.SET_SHOW_ADD_PROJECT_FORM(true)
      },
      handleCriteriaChange() {
        this.loading = true
        this.localdateRange = this.localdateRange || []
        this.SET_DATERANGE(this.localdateRange)
        this.setProjectView(this.selectedView, this.selectedCodeType)
      }
    },
    created: function() {
      this.localdateRange = this.newDateRange(-90, 30)
      this.SET_DATERANGE(this.localdateRange)
    },
    activated: async function() {
      const profileStr = window.localStorage.getItem('atomAuditProfile')
      const profile = JSON.parse(profileStr)

      if (profile && profile.defaultProjectList) {
        this.selectedView = profile.defaultProjectList || 'All Project Types'
        this.selectedCodeType = profile.defaultCodeType || 'All Code Types'
        this.selectedDisplayProcess = profile.defaultProcess || 'All Processes'
      }

      this.initialize()
    },
    components: {
      // addProject,
      coderReviewList,
      // projectCalendar,
      coderProAdvancedView,
      coderOutpatientAdvancedView,
      coderInpatientAdvancedView,
      coderAmbulatoryAdvancedView
    },
    computed: {
      ...mapState('managedLists/projects/', ['showAddProjectForm', 'newProjectID', 'projectList']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('globals/', ['dateRangePickerConfig']),
      ...mapFields(['showAddProjectForm']),
      ...mapGetters('globals/', ['GET_DATERANGE'])
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .projectListsTab {
    margin: 10px 0px 0px 0px;
  }

  >>> .statCol {
    min-width: 70px;
    max-width: 70px;
  }

  >>> .finImpactCol {
    min-width: 90px;
    max-width: 90px;
  }
</style>
