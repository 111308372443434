<template>
  <div>
    <el-row class="header-row">
      <el-col :span="16">
        <h3 class="el-page-header">Record Detail</h3>
        <div>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/my-projects' }">My Projects</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: `/my-projects/${ipWorksheet.projectIdentifier}` }"> {{ ipWorksheet.projectId }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: `/my-projects/inpatient/${$route.params.drgTableId}` }">Record Detail</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30" v-if="loading">
      <el-col :span="24">
        <div v-loading="loading" element-loading-spinner="atom-audit-loader"></div>
      </el-col>
    </el-row>
    <el-card class="coderContainerCard" style="min-height: 100px;">
      <el-row :gutter="30" v-if="!loading">
        <el-col :span="16">
          <el-button class="hiaButton pull-right" icon="el-icon-document" size="mini" @click="openWorksheet" plain round>Worksheet</el-button>
          <h5>Audit Results</h5>
          <coderInpatientDemographics style="margin: 0px 0px 20px 10px;" :worksheet="ipWorksheet" class="collapseContent"></coderInpatientDemographics>
          <el-collapse id="reviewAccordion" v-model="activeNames" class="ipCollapse">
            <el-collapse-item name="dxs">
              <template slot="title">
                <span style="width: 100%;">
                  Diagnosis Codes
                  <span class="codeTotalHeader"> - {{ ipWorksheet.dxs.length }} Total</span>
                </span>
              </template>
              <coderInpatientDxs :worksheet="ipWorksheet"></coderInpatientDxs>
            </el-collapse-item>
            <el-collapse-item name="pxs">
              <template slot="title">
                <span style="width: 100%;">
                  Procedure Codes
                  <span class="codeTotalHeader"> - {{ ipWorksheet.pxs.length }} Total</span>
                </span>
              </template>
              <coderInpatientPxs :worksheet="ipWorksheet"></coderInpatientPxs>
            </el-collapse-item>
            <el-collapse-item title="Audit Findings" name="auditFindings">
              <coderInpatientFindings :worksheet="ipWorksheet"></coderInpatientFindings>
            </el-collapse-item>
          </el-collapse>
        </el-col>
        <el-col :span="8" class="sticky">
          <h5>Coder Responses</h5>
          <el-form ref="form" id="form" label-position="left" label-width="200px" size="mini" :key="1" style="margin-top: 0px;margin-left: 10px;">
            <el-form-item v-if="ipWorksheet.auditResult" size="mini" label="Audit Result">
              <div style="margin: 6px 0px 0px 15px;">{{ ipWorksheet.auditResult }}</div>
            </el-form-item>
            <el-form-item v-if="ipWorksheet.responseNeeded" size="mini" label="Response Needed">
              <div style="margin: 6px 0px 0px 15px;">{{ ipWorksheet.responseNeeded }}</div>
            </el-form-item>
            <el-form-item v-if="ipWorksheet.finalOutcome" size="mini" label="Final Outcome">
              <div style="margin: 6px 0px 0px 15px;">{{ ipWorksheet.finalOutcome }}</div>
            </el-form-item>
          </el-form>
          <coderInpatientResponses :drgTableId="ipWorksheet.drgTableID" @dirty="unsavedChanges = $event" @GET_IP_WORKSHEET="GET_IP_WORKSHEET" :worksheet="ipWorksheet"></coderInpatientResponses>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import coderInpatientDemographics from './coderInpatientDemographics'
  import coderInpatientDxs from './coderInpatientDxs'
  import coderInpatientPxs from './coderInpatientPxs'
  import coderInpatientFindings from './coderInpatientFindings'
  import coderInpatientResponses from './coderInpatientResponses'

  export default {
    name: 'CoderInpatientReview',
    data: function() {
      return {
        loading: true,
        unsavedChanges: false,
        activeNames: ['dxs', 'pxs', 'auditFindings']
      }
    },
    components: {
      coderInpatientDemographics,
      coderInpatientDxs,
      coderInpatientPxs,
      coderInpatientFindings,
      coderInpatientResponses
    },
    methods: {
      ...mapActions('coder/', ['GET_IP_WORKSHEET']),
      ...mapMutations('rebuttals/', ['SET_REBUTTAL']),
      ...mapActions('dropdowns', ['GET_DROPDOWNS']),
      async getWorksheet() {
        try {
          this.loading = true
          await this.GET_IP_WORKSHEET(this.$route.params.drgTableId)
          // update vuex rebuttal - rebuttals are managed in vuex after this call
          this.SET_REBUTTAL(this.ipWorksheet.rebuttal)
          this.loading = false
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Error getting coder worksheet',
            type: 'error'
          })
          console.log(e)
        }
      },
      openWorksheet() {
        const cacheBuster = new Date().getTime()
        this.worksheetWindow = window.open(`/my-projects/inpatient/worksheet/${this.ipWorksheet.drgTableID}?dt=${cacheBuster}&fullscreen=true`, 'worksheet', 'height=850,width=1600')
      },
      confirmLeave() {
        return window.confirm('Changes you made may not be saved.')
      },
      confirmStayInDirtyForm() {
        return this.unsavedChanges && !this.confirmLeave()
      },
      beforeWindowUnload(e) {
        if (this.confirmStayInDirtyForm()) {
          e.preventDefault()
          e.returnValue = ''
        }
      }
    },
    beforeRouteLeave(to, from, next) {
      if (this.confirmStayInDirtyForm()) {
        next(false)
      } else {
        // Navigate to next view
        next()
      }
    },
    created: async function() {
      this.loading = true
      await this.getWorksheet()
      this.loading = false
      window.addEventListener('beforeunload', this.beforeWindowUnload)
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
    computed: {
      ...mapState('coder/', ['ipWorksheet'])
    },
    watch: {}
  }
</script>

<style scoped>
  h5 {
    margin: 0px 0px 20px 10px;
    font-size: 14px;
  }

  .sticky {
    position: sticky;
    top: 10px;
  }

  >>> .hiaTable tr td {
    border: none !important;
  }

  >>> .hiaTable th {
    border: none !important;
  }

  >>> .coderContainerCard {
    overflow: unset;
  }
</style>
