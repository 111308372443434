<template>
  <div>
    <div>
      <v-client-table key="table" style="min-height: 120px;" class="hiaTable" ref="reviewListGrid" id="reviewListGrid" :data="projectList" :columns="columns"
        :options="options" v-loading="projectLoading" element-loading-spinner="atom-audit-loader">
        <template slot="projectName" slot-scope="props">
          <div class="overflow" :title="props.row.projectName">
            <router-link v-bind:to="'/my-projects/' + props.row.projectID">{{ props.row.projectName ? props.row.projectName : '' }}</router-link>
          </div>
        </template>

        <template slot="clientName" slot-scope="props">
          <div class="overflow" :title="props.row.clientName">
            {{ props.row.clientName }}
          </div>
        </template>

        <template slot="leadName" slot-scope="props">
          <div class="overflow" :title="props.row.leadName">
            {{ props.row.leadName }}
          </div>
        </template>

        <template slot="startDate" slot-scope="props">
          <div>
            {{ props.row.startDate ? formatDate(props.row.startDate) : '' }}
          </div>
        </template>
        <template slot="endDate" slot-scope="props">
          <div>
            {{ props.row.endDate ? formatDate(props.row.endDate) : '' }}
          </div>
        </template>

        <template slot="identifiers" slot-scope="props">
          <template v-if="props.row.identifiers">
            <div class="el-tag el-tag--mini el-tag--light" style="max-width: 150px; " v-for="tag in props.row.identifiers.split(',')" :key="tag">
              <div :title="tag" style="overflow: hidden; text-overflow: ellipsis;">
                {{ tag }}
              </div>
            </div>
          </template>
        </template>

        <template slot="delete" slot-scope="props">
          <el-popover v-if="projectManagement()" :ref="'delete_' + props.row.projectID" style="word-break: normal;" @show="handleDeletePopover(props.row)"
            :popper-class="'delete_' + props.row.projectID" trigger="click" placement="left" width="200">
            <center>
              <p style="word-break: normal;">{{ deletePopperContent }}</p>
              <el-button-group>
                <el-button class="hiaButton" plain round type="text" size="mini" @click="deleteProject(props.row, 'delete_' + props.row.projectID, false)">Cancel</el-button>
                <el-button :disabled="!canDeleteProject(props.row)" class="hiaButton" plain round size="mini"
                  @click="deleteProject(props.row, 'delete_' + props.row.projectID, true)">Confirm</el-button>
              </el-button-group>
            </center>
            <div slot="reference">
              <div title="delete" style="text-align: center; cursor: pointer;">
                <i class="el-icon-close delete-button"></i>
              </div>
            </div>
          </el-popover>
        </template>
      </v-client-table>
    </div>
  </div>
</template>

<script>
  // state
  import { mapState, mapActions, mapGetters } from 'vuex'

  // components

  // import {AgGridVue} from 'ag-grid-vue'

  // mixins
  import dateHelper from '@/mixins/date-helper'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'coderReviewList',
    mixins: [dateHelper, HiaAuthorization],
    data() {
      return {
        format: format,
        parseISO: parseISO,
        columns: ['projectName', 'projectTypeName', 'clientName', 'identifiers', 'leadName', 'startDate', 'endDate', 'recordsLeft', 'coderResponseNeeded', 'coderResponseCompleted', 'projectStatus'],
        options: {
          filterByColumn: true,
          listColumns: {},
          columnsClasses: {
            projectName: 'wideColumn',
            identifiers: 'tagsCol'
          },
          orderBy: {
            column: 'startDate',
            ascending: false
          },
          headings: {
            clientName: 'Facility',
            projectName: 'Project Name',
            name: 'Coder',
            identifier: 'Tags',
            identifiers: 'Tags',
            leadName: 'Lead',
            startDate: 'Start Date',
            endDate: 'End Date',
            recordsEntered: 'Records Entered',
            recordsReviewed: 'Records Reviewed',
            recordsLeft: 'Records Reviewed',
            projectTypeName: 'Project Type',
            projectStatus: 'Project Status',
            coderResponseNeeded: 'Coder Response Needed',
            coderResponseCompleted: 'Coder Response Completed',
            delete: ''
          },
          perPage: 50,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' },
          filterAlgorithm: {
            startDate(row, query) {
              if (row.startDate) {
                return format(parseISO(row.startDate), 'M/dd/yyyy').includes(query)
              }
              return false
            },
            endDate(row, query) {
              if (row.endDate) {
                return format(parseISO(row.endDate), 'M/dd/yyyy').includes(query)
              }
              return false
            }
          }
        },
        dropdownsNeeded: ['projectIdentifiers'],
        listloading: false,
        deletePopperContent: ''
      }
    },
    methods: {
      ...mapActions('managedLists/projects/', ['LOAD_PROJECT_LIST', 'DELETE_PROJECT']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS', 'CONVERT_DROPDOWN_COLUMNFILTERARRAY']),
      async initialize() {
        // const response = this.GET_COLUMNFILTERARRAY('projectStatus')
        // const statusKeys = response.map(m => { return { id: m.text, text: m.text } })
        // this.options.listColumns.projectStatus = statusKeys
      },
      async deleteProject(row, ref, bool) {
        const popper = this.$refs[ref]
        popper.doDestroy()
        popper.doClose()
        if (!bool) {
          return false
        }

        try {
          await this.DELETE_PROJECT(row)
          await this.LOAD_PROJECT_LIST(this.GET_DATERANGE())
        } catch (e) {
          this.$message({
            message: 'Error Deleting Project',
            type: 'error'
          })
        }
      },
      handleDeletePopover(row) {
        if (row.allRecords > 0) {
          this.deletePopperContent = 'This project has records entered and cannot be deleted, move records first.'
          return
        }
        this.deletePopperContent = 'Delete this Project?'
      },
      canDeleteProject(row) {
        if (row.allRecords > 0) {
          return false
        }
        return true
      }
    },
    computed: {
      ...mapState('managedLists/projects/', ['showAddProjectForm', 'dropdownsLoading', 'projectList', 'projectLoading']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapGetters('globals/', ['GET_DATERANGE']),
      ...mapGetters('dropdowns/', ['GET_COLUMNFILTERARRAY'])
    },
    mounted: function () {
      this.initialize()
    }
  }
</script>

<style scoped>
  >>>.tagsCol {
    max-width: 150px;
    overflow: hidden;
  }
</style>

<style>
  #reviewListGrid tr td:nth-child(14) {
    width: 3%;
  }

  #reviewListGrid tr td:nth-child(16),
  #reviewListGrid tr td:nth-child(17) {
    width: 1%;
  }

  .wideColumn {
    min-width: 200px;
  }

  .overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 80px;
    text-overflow: ellipsis;
  }
</style>