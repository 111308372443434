<template>
  <div>
    <el-form size="mini" ref="form" label-position="left" label-width="170px" v-loading="loading" element-loading-spinner="atom-audit-loader">
      <el-row :gutter="30">
        <el-col :span="12">
          <h4 class="headerWithBorder">Chart Demographic</h4>

          <el-form-item size="mini" label="Patient Number">
            <el-input disabled size="mini" :maxlength="15" type="text" id="patAcctNum" v-model="worksheet.patAcctNum"> </el-input>
          </el-form-item>

          <el-form-item size="mini" label="MR Number">
            <el-input :disabled="disabled" size="mini" :maxlength="15" type="text" id="mrNumber" v-model="worksheet.mrNumber"> </el-input>
          </el-form-item>

          <el-form-item size="mini" label="Payor">
            <el-input :disabled="disabled" v-model="worksheet.payorName" placeholder="Payor" itemkey="key" label="value"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Payor Detail">
            <el-input :disabled="disabled" v-model="worksheet.payorDetail" placeholder="Payor Detail" itemkey="key" label="value"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Discharge Date">
            <el-date-picker :disabled="disabled" size="mini" type="date" id="dischargeDate" v-model="worksheet.dischargeDate" format="MM/dd/yyyy"> </el-date-picker>
          </el-form-item>

          <el-form-item size="mini" label="IP Admit Date">
            <el-date-picker :disabled="disabled" size="mini" type="date" id="admitDate" v-model="worksheet.admitDate" format="MM/dd/yyyy"></el-date-picker>
          </el-form-item>

          <el-form-item size="mini" label="Age">
            <el-input disabled v-model="worksheet.patientAge"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Patient Sex">
            <el-input disabled v-model="worksheet.sex"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Length of Stay">
            <el-input disabled v-model="worksheet.los"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <h4 class="headerWithBorder">Review Information</h4>

          <el-form-item size="mini" label="Facility">
            <el-input disabled v-model="worksheet.clientName"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Auditor">
            <el-input disabled v-model="worksheet.reviewer"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Review Date">
            <el-date-picker disabled size="mini" type="date" id="revDate" v-model="worksheet.reviewDate" format="MM/dd/yyyy"> </el-date-picker>
          </el-form-item>

          <el-form-item size="mini" label="Coder">
            <el-input disabled v-model="worksheet.coder"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="D/C Summ. Avail at Coding">
            <el-input disabled v-model="worksheet.dcSummAvailAtCoding"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="UB Available">
            <el-input disabled :value="worksheet.noUBAvailable ? 'No' : 'Yes'"></el-input>
            <!-- {{worksheet.noUBAvailable ? 'No' : 'Yes'}} -->
          </el-form-item>

          <el-form-item size="mini" label="Physician">
            <el-input disabled v-model="worksheet.physician"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Reason for Change">
            <el-input disabled v-model="worksheet.reasonForChangeDesc"></el-input>
          </el-form-item>

          <!-- <el-form-item size="mini" label="Audit Result">
            <el-input disabled v-model="worksheet.auditResult"></el-input>
          </el-form-item> -->
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :xl="8" :sm="12" class="top-margin">
          <h4 class="headerWithBorder">Original Coding Details</h4>

          <el-form-item size="mini" label="DRG">
            <el-input disabled size="mini" id="originalDrg" v-model="worksheet.orDRG"> </el-input>
          </el-form-item>

          <el-form-item size="mini" label="Weight">
            <el-input :disabled="disabled" size="mini" id="originalWeight" v-model="worksheet.orWt"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Patient Status">
            <el-input disabled v-model="worksheet.orPatStat"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Point of Origin">
            <el-input disabled v-model="worksheet.originalPOO"></el-input>
          </el-form-item>
        </el-col>

        <el-col :xl="8" :sm="12" class="top-margin">
          <h4 class="headerWithBorder">Coding Audit Determinations</h4>
          <el-form-item size="mini" label="Recommended DRG">
            <el-input disabled size="mini" id="originalDrg" v-model="worksheet.recDRG">
              <el-button v-if="worksheet.recDRG" slot="suffix" style="margin: -2px 5px 0px 0px;" size="mini" type="text" @click="SET_RESPONSE_DRG({ originalDrg: worksheet.orDRG, recommendedDrg: worksheet.recDRG })">Respond </el-button>
            </el-input>
          </el-form-item>

          <el-form-item size="mini" label="Rec. Weight">
            <el-input :disabled="disabled" size="mini" id="originalWeight" v-model="worksheet.recWt"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Rec. Patient Status">
            <el-input disabled v-model="worksheet.recPatstat">
              <el-button :disabled="readOnly()" v-if="worksheet.recPatstat" slot="suffix" style="margin: -2px 5px 0px 0px;" size="mini" type="text" @click="SET_RESPONSE_PATSTAT({ originalPatStat: worksheet.orPatStat, recommendedPatStat: worksheet.recPatstat })">Respond </el-button>
            </el-input>
          </el-form-item>

          <el-form-item size="mini" label="Rec. Point of Origin">
            <el-input disabled v-model="worksheet.recommendedPOO">
              <el-button :disabled="readOnly()" v-if="worksheet.recommendedPOO" slot="suffix" style="margin: -2px 5px 0px 0px;" size="mini" type="text" @click="SET_RESPONSE_POO({ originalPoo: worksheet.originalPOO, recommendedPoo: worksheet.recommendedPOO })">Respond </el-button>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :xl="8" :sm="12" class="top-margin">
          <h4 class="headerWithBorder">Query Analysis</h4>

          <el-form-item size="mini" label="Coder Query">
            <el-input disabled :value="worksheet.coderQuery ? 'Yes' : 'No'"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Coder Query Missed">
            <el-input disabled :value="worksheet.coderQueryMissed ? 'Yes' : 'No'"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Reviewer Query">
            <el-input disabled :value="worksheet.auditQuery ? 'Yes' : 'No'"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import dateHelper from '@/mixins/date-helper'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import { format, parseISO } from 'date-fns'
  import { mapMutations } from 'vuex'

  export default {
    name: 'coderInpatientDemographics',
    mixins: [dateHelper, HiaAuthorization],
    props: ['worksheet'],
    data() {
      return {
        format: format,
        parseISO: parseISO,
        disabled: true,
        loading: false
      }
    },
    methods: {
      ...mapMutations('coder/', ['SET_RESPONSE_PATSTAT', 'SET_RESPONSE_DRG', 'SET_RESPONSE_POO'])
    }
  }
</script>
