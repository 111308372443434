<template>
  <div>
    <el-row class="header-row">
      <el-col :span="16">
        <h3 class="el-page-header">Record Detail</h3>
        <div>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/my-projects' }">My Projects</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: `/my-projects/${opWorksheet.projectIdentifier}` }"> {{ opWorksheet.projectId }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: `/my-projects/outpatient/${$route.params.ascTableId}` }">Record Detail</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30" v-if="loading">
      <el-col :span="24">
        <div v-loading="loading" element-loading-spinner="atom-audit-loader"></div>
      </el-col>
    </el-row>
    <el-card class="coderContainerCard" style="min-height: 100px;">
      <el-row :gutter="30" v-if="!loading">
        <el-col :span="16">
          <el-button class="hiaButton pull-right" icon="el-icon-document" size="mini" @click="openWorksheet" plain round>Worksheet</el-button>
          <h5>Audit Results</h5>
          <coderOutpatientDemographics style="margin: 0px 0px 20px 10px;" :worksheet="opWorksheet" class="collapseContent"></coderOutpatientDemographics>
          <el-collapse id="reviewAccordion" v-model="activeNames">
            <el-collapse-item name="dxs">
              <template slot="title">
                <span style="width: 100%;">
                  Diagnosis Codes
                  <span class="codeTotalHeader"> - {{ opWorksheet.dxs.length }} Total</span>
                </span>
              </template>
              <coderOutpatientDxs :worksheet="opWorksheet"></coderOutpatientDxs>
            </el-collapse-item>

            <el-collapse-item name="pxs" v-if="opWorksheet.outpatientGrouperId === 1">
              <template slot="title">
                <span style="width: 100%;">
                  Procedure Codes
                  <span class="codeTotalHeader"> - {{ opWorksheet.pxs.length }} Total</span>
                </span>
              </template>
              <coderOutpatientPxs :worksheet="opWorksheet"></coderOutpatientPxs>
            </el-collapse-item>

            <el-collapse-item name="cpts">
              <template slot="title">
                <span style="width: 100%;">
                  CPT Codes
                  <span class="codeTotalHeader"> - {{ opWorksheet.cpts.length }} Total</span>
                </span>
              </template>
              <coderOutpatientCpts :worksheet="opWorksheet"></coderOutpatientCpts>
            </el-collapse-item>

            <el-collapse-item title="Audit Findings" name="auditFindings">
              <coderOutpatientFindings :worksheet="opWorksheet"></coderOutpatientFindings>
            </el-collapse-item>
          </el-collapse>
        </el-col>
        <el-col :span="8" class="sticky">
          <h5>Responses</h5>
          <el-form ref="form" id="form" label-position="left" label-width="200px" size="mini" :key="1" style="margin-top: 0px;margin-left: 10px;">
            <el-form-item v-if="opWorksheet.auditResult" size="mini" label="Audit Result">
              <div style="margin: 6px 0px 0px 15px;">{{ opWorksheet.auditResult }}</div>
            </el-form-item>
            <el-form-item v-if="opWorksheet.responseNeeded" size="mini" label="Response Needed">
              <div style="margin: 6px 0px 0px 15px;">{{ opWorksheet.responseNeeded }}</div>
            </el-form-item>
            <el-form-item v-if="opWorksheet.finalOutcome" size="mini" label="Final Outcome">
              <div style="margin: 6px 0px 0px 15px;">{{ opWorksheet.finalOutcome }}</div>
            </el-form-item>
          </el-form>
          <coderOutpatientResponses :ascTableId="opWorksheet.ascTableId" @dirty="unsavedChanges = $event" @GET_OP_WORKSHEET="GET_OP_WORKSHEET" :worksheet="opWorksheet"></coderOutpatientResponses>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import coderOutpatientDemographics from './coderOutpatientDemographics'
  import coderOutpatientDxs from './coderOutpatientDxs'
  import coderOutpatientCpts from './coderOutpatientCpts'
  import coderOutpatientPxs from './coderOutpatientPxs'
  import coderOutpatientFindings from './coderOutpatientFindings'
  import coderOutpatientResponses from './coderOutpatientResponses'

  export default {
    name: 'CoderProReview',
    data: function() {
      return {
        loading: true,
        unsavedChanges: false,
        activeNames: ['dxs', 'pxs', 'cpts', 'auditFindings'],
        dropdownsNeeded: ['ascPaymentIndicators']
      }
    },
    components: {
      coderOutpatientDemographics,
      coderOutpatientDxs,
      coderOutpatientCpts,
      coderOutpatientPxs,
      coderOutpatientFindings,
      coderOutpatientResponses
    },
    methods: {
      ...mapActions('coder/', ['GET_OP_WORKSHEET']),
      ...mapMutations('rebuttals/', ['SET_REBUTTAL']),
      ...mapActions('dropdowns', ['GET_DROPDOWNS']),
      async getWorksheet() {
        try {
          this.loading = true
          await Promise.all([this.GET_OP_WORKSHEET(this.$route.params.ascTableId), this.GET_DROPDOWNS(this.dropdownsNeeded)])
          // update vuex rebuttal - rebuttals are managed in vuex after this call
          this.SET_REBUTTAL(this.opWorksheet.rebuttal)
          this.loading = false
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Error getting coder worksheet',
            type: 'error'
          })
          console.log(e)
        }
      },
      openWorksheet() {
        const cacheBuster = new Date().getTime()
        this.worksheetWindow = window.open(`/my-projects/outpatient/worksheet/${this.opWorksheet.ascTableId}?dt=${cacheBuster}&fullscreen=true`, 'worksheet', 'height=850,width=1600')
      },
      confirmLeave() {
        return window.confirm('Changes you made may not be saved.')
      },
      confirmStayInDirtyForm() {
        return this.unsavedChanges && !this.confirmLeave()
      },
      beforeWindowUnload(e) {
        if (this.confirmStayInDirtyForm()) {
          e.preventDefault()
          e.returnValue = ''
        }
      }
    },
    beforeRouteLeave(to, from, next) {
      if (this.confirmStayInDirtyForm()) {
        next(false)
      } else {
        // Navigate to next view
        next()
      }
    },
    created: async function() {
      this.loading = true
      await this.getWorksheet()
      this.loading = false
      window.addEventListener('beforeunload', this.beforeWindowUnload)
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
    computed: {
      ...mapState('coder/', ['opWorksheet'])
    },
    watch: {}
  }
</script>

<style scoped>
  h5 {
    margin: 0px 0px 20px 10px;
    font-size: 14px;
  }

  .sticky {
    position: sticky;
    top: 10px;
  }

  >>> .hiaTable tr td {
    border: none !important;
  }

  >>> .hiaTable th {
    border: none !important;
  }

  >>> .coderContainerCard {
    overflow: unset;
  }
</style>
