<template>
  <div>
    <el-form size="mini" ref="form" label-position="left" label-width="170px" v-loading="loading" element-loading-spinner="atom-audit-loader">
      <el-row :gutter="30">
        <el-col :span="12">
          <h4 class="headerWithBorder">Chart Demographic</h4>

          <el-form-item size="mini" label="Patient Number">
            <el-input disabled size="mini" :maxlength="15" type="text" id="patientNumber" v-model="worksheet.patAcctNum"> </el-input>
          </el-form-item>

          <el-form-item size="mini" label="MR Number">
            <el-input :disabled="disabled" size="mini" :maxlength="15" type="text" id="mrNumber" v-model="worksheet.mrNumber"> </el-input>
          </el-form-item>

          <el-form-item size="mini" label="Payor">
            <el-input :disabled="disabled" v-model="worksheet.payorName" placeholder="Payor" itemkey="key" label="value"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Payor Detail">
            <el-input :disabled="disabled" v-model="worksheet.payorDetail" placeholder="Payor Detail" itemkey="key" label="value"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Date of Service">
            <el-date-picker :disabled="disabled" size="mini" type="date" id="dischargeDate" v-model="worksheet.dateOfService" format="MM/dd/yyyy"> </el-date-picker>
          </el-form-item>

          <el-form-item size="mini" label="Age">
            <el-input disabled v-model="worksheet.patientAge"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Patient Sex">
            <el-input disabled v-model="worksheet.patientSex"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <h4 class="headerWithBorder">Review Information</h4>

          <el-form-item size="mini" label="Facility">
            <el-input disabled v-model="worksheet.clientName"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Auditor">
            <el-input disabled v-model="worksheet.reviewerName"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Review Date">
            <el-date-picker disabled size="mini" type="date" id="revDate" v-model="worksheet.reviewDate" format="MM/dd/yyyy"> </el-date-picker>
          </el-form-item>

          <el-form-item size="mini" label="Coder">
            <el-input disabled v-model="worksheet.coder"></el-input>
          </el-form-item>

          <el-form-item size="mini" label="Provider">
            <el-input disabled v-model="worksheet.physician"></el-input>
          </el-form-item>

          <!-- <el-form-item size="mini" label="Audit Result">
            <el-input disabled v-model="worksheet.auditResult"></el-input>
          </el-form-item> -->
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :xl="8" :sm="12" class="top-margin">
          <h4 class="headerWithBorder">Original Coding Details</h4>

          <el-form-item size="mini" label="Patient Status">
            <el-input disabled v-model="worksheet.originalPatientStatus"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import dateHelper from '@/mixins/date-helper'
  import { format, parseISO } from 'date-fns'
  import { mapMutations } from 'vuex'

  export default {
    name: 'coderInpatientDemographics',
    mixins: [dateHelper],
    props: ['worksheet'],
    data() {
      return {
        format: format,
        parseISO: parseISO,
        disabled: true,
        loading: false
      }
    },
    methods: {
      ...mapMutations('coder/', ['SET_RESPONSE_PATSTAT', 'SET_RESPONSE_DRG', 'SET_RESPONSE_POO'])
    }
  }
</script>
