<template>
  <div :id="`${this._uid}_ProRiskAnalysisChart`"></div>
</template>

<script>
  // import DetailsPopOver from './DetailsPopOver'

  export default {
    name: 'ProRiskAnalysisChart',
    components: {},
    props: ['seriesData', 'medicareCompare', 'specialtyCompare', 'providerSpecialtiesCompare', 'type', 'providerName'],
    data: function() {
      return {
        highCharts: null,
        chartTitleStyle: {
          fontSize: '5px'
        },
        dataLabelsStyle: {
          color: 'black'
        },
        showPercentage: false,
        localSeriesData: []
      }
    },
    methods: {
      getSeriesData() {
        this.localSeriesData = []
        this.showPercentage = false
        if (this.medicareCompare || this.specialtyCompare || this.providerSpecialtiesCompare) {
          // special logic for any swapping with frequency or percentage
          // swapping frequency with percentages on graph
          // swapping frequency with percentage on datagrid
          // yAxis label swapping frequency with percentage

          this.showPercentage = !(this.type === 'providerSpecialty' && this.specialtyCompare && this.providerSpecialtiesCompare)
        }

        const category = this.seriesData.find(f => f.seriesType === 'category')
        if (category) {
          const levels = category.labels

          const data = levels.map((level, index) => {
            return [level, this.showPercentage ? category.percentages[index] : category.frequencies[index]]
          })

          this.localSeriesData.push({
            name: `${this.providerName} - ${category.seriesTitle}`,
            data: data,
            color: '#7f98b4',
            fillOpacity: 0.6
            // baseSeries: 1,
            // marker: {
            //   radius: 1
            // }
            // fillOpacity: 0.2,
            // color: 'lightgreen'
          })
        }

        if (this.medicareCompare) {
          const medicare = this.seriesData.find(f => f.seriesType === 'medicare')
          if (medicare) {
            const levels1 = medicare.labels

            const data1 = levels1.map((level, index) => {
              return [level, medicare.percentages[index]]
            })

            this.localSeriesData.push({
              name: `${medicare.seriesTitle}`,
              data: data1,
              color: '#fee18f',
              fillOpacity: 0.6
              // baseSeries: 1,
              // marker: {
              //   radius: 1
              // }
              // fillOpacity: 0.2,
              // color: 'lightgreen'
            })
          }
        }

        if (this.specialtyCompare) {
          const mySpecialty = this.seriesData.find(f => f.seriesType === 'mySpecialty')
          if (mySpecialty) {
            const levels2 = mySpecialty.labels

            const data2 = levels2.map((level, index) => {
              return [level, mySpecialty.percentages[index]]
            })

            this.localSeriesData.push({
              name: mySpecialty.seriesTitle,
              data: data2,
              color: '#f57fc5',
              fillOpacity: 0.6
              // fillOpacity: 0.2,
              // color: 'lightgreen'
            })
          }
        }

        if (this.providerSpecialtiesCompare) {
          const specialties = this.seriesData.find(f => f.seriesType === 'specialties')
          const levels3 = specialties.labels

          const data3 = levels3.map((level, index) => {
            return [level, specialties.percentages[index]]
          })

          this.localSeriesData.push({
            name: specialties.seriesTitle,
            data: data3,
            color: '#7cbe5f',
            fillOpacity: 0.6
            // fillOpacity: 0.2,
            // color: 'thistle'
          })
        }
      },
      renderChart() {
        this.getSeriesData()
        const localShowPercentage = this.showPercentage
        this.highCharts.chart(`${this._uid}_ProRiskAnalysisChart`, {
          chart: {
            type: 'areaspline',
            height: 200,
            backgroundColor: null
            // spacingBottom: 0,
            // spacingTop: 0,
            // spacingLeft: 0,
            // spacingRight: 0
            // width: 700
          },
          title: {
            // text: 'My Timesheet Tasks',
            text: null
            // style: this.chartTitleStyle
          },
          tooltip: {
            formatter: function() {
              return localShowPercentage ? `${this.series.name}: ${this.y.toFixed(2)}%` : `${this.series.name}: ${this.y}`
            }
            // pointFormatter: function() {
            //   return this.showPercentage ? `${this.series.name}: ${this.y.toFixed(2)}%` : `${this.series.name}: ${this.y}`
            // }
            // formatter: function() {
            //   let s = `<span style="font-size: 10px;">${this.x}</span>`
            //   let total = 0
            //   this.points.forEach(point => {
            //     s += `<br /><span style="color:${point.color}">●</span> ${point.series.name}: <b>${point.y}</b>`
            //     total += point.y
            //   })
            //   s += `<br/><span style="color: transparent;">●</span> Total: <b>${total}</b>`
            //   return s
            // },
            // pointFormat: '<span style="color:{point.color}">●</span> {point.y}'
            // shared: true,
            // crosshairs: true
          },
          xAxis: {
            type: 'category'
            // min: 0,
            // categories: this.categories,
            // title: {
            //   text: this.xTitle
            // }
          },
          yAxis: {
            allowDecimals: false,
            title: false,
            labels: {
              formatter: function() {
                return localShowPercentage ? this.value + '%' : this.value
              }
            }
            // min: 0,
            // title: {
            //   text: this.yTitle
            //   // align: 'high'
            // },
            // labels: {
            //   overflow: 'justify'
            // }
          },
          legend: {
            // enabled: true
            // reversed: false,
            // verticalAlign: 'top'
          },
          plotOptions: {
            // line: {
            //   dataLabels: {
            //     enabled: true
            //   },
            //   enableMouseTracking: false
            // },
            // series: {
            //   cursor: 'pointer',
            //   // className: 'popup-on-click',
            //   marker: {
            //     lineWidth: 1
            //   }
            // }
          },
          credits: {
            enabled: false
          },
          series: this.localSeriesData
        })
      }
    },
    created: function() {},
    mounted: function() {
      import(/* webpackChunkName: "highcharts" */ 'highcharts').then(({ default: hc }) => {
        this.highCharts = hc
      })
    },
    computed: {},
    watch: {
      seriesData() {
        this.renderChart()
      },
      highCharts() {
        if (this.highCharts) {
          this.renderChart()
        }
      },
      medicareCompare() {
        this.renderChart()
      },
      specialtyCompare() {
        this.renderChart()
      }
      // providerSpecialtiesCompare() {
      //   this.renderChart()
      // }
    }
  }
</script>

<style scoped>
  >>> .highcharts-container {
    width: 100% !important;
    height: 100% !important;
  }

  >>> .highcharts-root {
    width: 100% !important;
    height: 100% !important;
  }
  >>> .highcharts-background {
    fill: transparent !important;
  }
</style>
