<template>
  <div>
    <v-client-table key="cptGrid" class="hiaTable" ref="cptGrid" id="cptGrid" :class="{ truCodeEnabled: isTrucodeEnabled() }" :data="worksheet.cpts" :columns="pxColumns" :options="options" uniqueKey="cptId">
      <template slot="h__originalCpt">
        <div>Orig. CPT</div>
        <div v-if="isTrucodeEnabled()" class="feeScheduleHeader">
          <div v-if="worksheet.outpatientGrouperId === 1" class="feeScheduleHeaderChild">APC</div>
          <div v-if="worksheet.outpatientGrouperId === 2" class="feeScheduleHeaderChild">ASC PI</div>
        </div>
      </template>

      <template slot="h__originalModifiers">
        <div>Mods</div>
        <div v-if="isTrucodeEnabled()" class="feeScheduleHeader">
          <div v-if="worksheet.outpatientGrouperId === 1" class="feeScheduleHeaderChild">APC Wt.</div>
          <div v-if="worksheet.outpatientGrouperId === 2" class="feeScheduleHeaderChild">ASC Wt.</div>
        </div>
      </template>

      <template slot="h__origDesc">
        <div>Desc</div>
        <div v-if="isTrucodeEnabled()" style="display: flex" class="feeScheduleHeader">
          <div class="feeScheduleHeaderChild" style="flex: 1 1">
            Pay Rate
          </div>
          <div v-if="worksheet.outpatientGrouperId === 1" class="feeScheduleHeaderChild" style="flex: 1 1; margin-left: 10px;">
            SI
          </div>
          <div v-if="worksheet.outpatientGrouperId === 1" class="feeScheduleHeaderChild" style="flex: 1 1; margin-left: 10px;">
            APC Pay
          </div>
          <div v-if="worksheet.outpatientGrouperId === 2" class="feeScheduleHeaderChild" style="flex: 1 1">
            ASC Pay
          </div>
        </div>
      </template>

      <template slot="h__recommendedCpt">
        <div>Rec. CPT</div>
        <div v-if="isTrucodeEnabled()" class="feeScheduleHeader">
          <div v-if="worksheet.outpatientGrouperId === 1">Rec. APC</div>
          <div v-if="worksheet.outpatientGrouperId === 2">Rec. ASC PI</div>
        </div>
      </template>

      <template slot="h__recommendedModifiers">
        <div>Mods</div>
        <div v-if="isTrucodeEnabled()" class="feeScheduleHeader">
          <div v-if="worksheet.outpatientGrouperId === 1">Rec. APC Wt.</div>
          <div v-if="worksheet.outpatientGrouperId === 2">Rec. ASC Wt.</div>
        </div>
      </template>

      <template slot="h__recDesc">
        <div>Desc</div>
        <div v-if="isTrucodeEnabled()" style="display: flex" class="feeScheduleHeader">
          <div class="feeScheduleHeaderChild" style="flex: 1 1">
            Rec. Pay Rate
          </div>
          <div v-if="worksheet.outpatientGrouperId === 1" class="feeScheduleHeaderChild" style="flex: 1 1; margin-left: 10px;">
            Rec. SI
          </div>
          <div v-if="worksheet.outpatientGrouperId === 1" class="feeScheduleHeaderChild" style="flex: 1 1; margin-left: 10px;">
            Rec. APC Pay
          </div>
          <div v-if="worksheet.outpatientGrouperId === 2" class="feeScheduleHeaderChild" style="flex: 1 1; margin-left: 10px;">
            Rec. ASC Pay
          </div>
        </div>
      </template>

      <template slot="originalCpt" slot-scope="props">
        <div>
          <!-- <el-input style="width: 112px;" :inputId="`originalCpt_${props.index}`" class="noRightBorder borderLeftRadius" v-model="props.row.originalCpt" :disabled="disabled">
          </el-input> -->
          {{ props.row.originalCpt }}
        </div>
        <div class="feeScheduleData" title="Original APC" v-if="isTrucodeEnabled() && worksheet.outpatientGrouperId === 1" v-html="getAPCData(props.row, 'apc', 0)"></div>
        <div class="feeScheduleData" v-if="isTrucodeEnabled() && worksheet.outpatientGrouperId === 2" v-html="getASCData(props.row, 'paymentIndicator', 0)"></div>
      </template>

      <template slot="originalModifiers" slot-scope="props">
        <div v-html="cptModifiers(props.row.originalModifiers)"></div>
        <div title="Original APC Weight" v-if="isTrucodeEnabled() && worksheet.outpatientGrouperId === 1" class="feeScheduleData" v-html="getAPCData(props.row, 'apcWeight', 0)"></div>
        <div title="Original ASC Weight" v-if="isTrucodeEnabled() && worksheet.outpatientGrouperId === 2" class="feeScheduleData" v-html="getASCData(props.row, 'ascWeight', 0)"></div>
      </template>

      <template slot="origDesc" slot-scope="props">
        <div style="text-transform: lowercase;" :title="props.row.origDesc">{{ truncateDescription(props.row.originalDescription) }}</div>
        <div v-if="isTrucodeEnabled()" style="display: flex;" class="feeScheduleData">
          <div style="flex: 1 1;">
            <div title="Original APC Payment Rate" v-if="worksheet.outpatientGrouperId === 1" v-html="getAPCData(props.row, 'apcPaymentRate', 0)"></div>
            <div title="Original ASC Payment Rate" v-if="worksheet.outpatientGrouperId === 2" v-html="getASCData(props.row, 'ascPaymentRate', 0)"></div>
          </div>
          <div style="flex: 1 1;" v-if="worksheet.outpatientGrouperId === 1">
            <div title="Original Status Indicator">
              <div v-html="getAPCData(props.row, 'statusIndicator', 0)"></div>
            </div>
          </div>
          <div style="flex: 1 1;">
            <div>
              <div title="Original APC Payment" v-if="worksheet.outpatientGrouperId === 1" v-html="getAPCData(props.row, 'apcPayment', 0)"></div>
              <div title="Original ASC Payment" v-if="worksheet.outpatientGrouperId === 2" v-html="getASCData(props.row, 'ascPayment', 0)"></div>
            </div>
          </div>
        </div>
      </template>

      <template slot="recommendedCpt" slot-scope="props">
        <div>
          <!-- <el-input disabled style="width: 112px;" :placeholder="props.row.originalCpt" :inputId="`recommendedCpt_${props.index}`" :class="{deleteCode: props.row.deleteCode}"
            v-model="props.row.recommendedCpt" @ellipsisClick="emitEllipsisClick(props.row.recommendedCpt)" @focus="emitFocusedCode(props.row.recommendedCpt)">
          </el-input> -->
          {{ props.row.originalDeleted ? 'DEL' : props.row.recommendedCpt }}
        </div>
        <div class="feeScheduleData" title="Recommended APC" v-if="isTrucodeEnabled() && worksheet.outpatientGrouperId === 1" v-html="getAPCData(props.row, 'apc', 1)"></div>
        <div class="feeScheduleData" v-if="isTrucodeEnabled() && worksheet.outpatientGrouperId === 2" v-html="getASCData(props.row, 'paymentIndicator', 1)"></div>
      </template>

      <template slot="recommendedModifiers" slot-scope="props">
        <div v-html="cptModifiers(props.row.recommendedModifiers)"></div>
        <div title="Recommended APC Weight" v-if="isTrucodeEnabled() && worksheet.outpatientGrouperId === 1" class="feeScheduleData" v-html="getAPCData(props.row, 'apcWeight', 1)"></div>
        <div title="Recommended ASC Weight" v-if="isTrucodeEnabled() && worksheet.outpatientGrouperId === 2" class="feeScheduleData" v-html="getASCData(props.row, 'ascWeight', 1)"></div>
      </template>

      <template slot="recDesc" slot-scope="props">
        <div style="text-transform: lowercase;" :title="props.row.originalDeleted ? 'Delete Code' : props.row.recDesc">
          {{ props.row.originalDeleted ? 'Delete Code' : truncateDescription(props.row.recommendedDesc) }}
        </div>
        <div v-if="isTrucodeEnabled()" style="display: flex;" class="feeScheduleData">
          <div style="flex: 1 1;" title="Recommended APC Payment Rate" v-if="worksheet.outpatientGrouperId === 1" v-html="getAPCData(props.row, 'apcPaymentRate', 1)"></div>
          <div style="flex: 1 1;" title="Recommended ASC Payment Rate" v-if="worksheet.outpatientGrouperId === 2" v-html="getASCData(props.row, 'ascPaymentRate', 1)"></div>
          <div title="Recommended Status Indicator" v-if="worksheet.outpatientGrouperId === 1" style="flex: 1 1; margin-left: 5px;" v-html="getAPCData(props.row, 'statusIndicator', 1)"></div>
          <div title="Recommended APC Payment" v-if="worksheet.outpatientGrouperId === 1" style="flex: 1 1; margin-left: 5px;" v-html="getAPCData(props.row, 'apcPayment', 1)"></div>
          <div title="Recommended ASC Payment" v-if="worksheet.outpatientGrouperId === 2" style="flex: 1 1; margin-left: 5px;" v-html="getASCData(props.row, 'ascPayment', 1)"></div>
        </div>
      </template>

      <template slot="reasonForChangeDescription" slot-scope="props">
        <!-- <el-input style="width: 82%" :disabled="disabled" @input="putInpatientPx(props.row, $event)" v-model="props.row.reasonForChange" placeholder="Reason for Change">
        </el-input> -->

        {{ props.row.reasonForChangeDescription }}
      </template>

      <template slot="response" slot-scope="props">
        <el-button style="margin-right: 5px;" size="mini" v-if="props.row.recommendedCpt || props.row.originalDeleted" type="text" @click="addResponse(props.row)">Respond </el-button>
      </template>
    </v-client-table>
  </div>
</template>

<script>
  import dateHelper from '@/mixins/date-helper'
  import { format, parseISO } from 'date-fns'
  import { mapMutations, mapGetters } from 'vuex'

  export default {
    name: 'coderInpatientPxs',
    mixins: [dateHelper],
    props: ['worksheet'],
    data() {
      return {
        format: format,
        parseISO: parseISO,
        disabled: true,
        loading: false,
        pxColumns: ['originalCpt', 'originalModifiers', 'origDesc', 'recommendedCpt', 'recommendedModifiers', 'recDesc', 'reasonForChangeDescription', 'response'],
        options: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          queue: [],
          networkFunction: null,
          perPage: 100,
          rowClassCallback: row => {
            if (row.recommendedCpt || row.originalDeleted) {
              return 'highlight'
            }
            return ''
          },
          columnsClasses: {
            originalCpt: 'tenPercent',
            origDesc: 'thirtyFivePercent',
            recommendedCpt: 'tenPercent',
            recDescription: 'thirtyFivePercent',
            reasonForChange: 'tenPercent'
          },
          headings: {
            originalCpt: 'Original CPT',
            origDesc: 'Desc.',
            recommendedCpt: 'Recommended CPT',
            recDesc: 'Desc.',
            reasonForChange: 'Reason For Change',
            response: '',
            reasonForChangeDescription: 'Reason For Change'
          }
        }
      }
    },
    methods: {
      ...mapMutations('coder/', ['SET_RESPONSE_ROW']),
      truncateDescription(description) {
        const max = 100
        if (description && description.length > max) {
          return description.substring(0, max) + '...'
        }
        return description
      },
      getGrouperflagsTitle(flagList) {
        if (!flagList) {
          return
        }

        const flagMap = {
          AD: 'Affects DRG',
          CC: 'Complication/Comorbidity',
          HC: 'Hospital-Acquired Condition',
          MC: 'Complication/Comorbidity',
          R: 'Affects Risk of Mortality',
          S: 'Affects Severity of Illness',
          AP: 'Affects Patient Safety Indicators',
          EP: 'Excludes Patient Safety Indicators',
          AQ: 'Affects Quality Measures',
          EQ: 'Excludes Quality Measures',
          OR: 'Operative Procedure',
          NOR: 'Non-Operative Procedure'
        }

        const flags = flagList.split(',')
        const title = []
        flags.forEach(x => {
          title.push(`${x}: ${flagMap[x]}`)
        })

        return title.join('\n')
      },
      addResponse(row) {
        this.SET_RESPONSE_ROW(row)
      },
      getASCData(cpt, field, level) {
        const row = this.worksheet.cptsGrouping.find(x => x.cptId === cpt.cptId)

        const orGrouping = row.ascRecommendationsCptsAscGrouping.find(x => x.codeLevel === 0)
        const recGrouping = row.ascRecommendationsCptsAscGrouping.find(x => x.codeLevel === 1)

        if (level === 1 && orGrouping && recGrouping && orGrouping.payment_indicator === recGrouping.payment_indicator && orGrouping.payment === recGrouping.payment) {
          return '&nbsp;'
        }

        if (level === 0 && !orGrouping) {
          return '&nbsp;'
        }

        if (level === 0 && orGrouping && !orGrouping.payment_indicator) {
          return '&nbsp;'
        }

        if (level === 1 && !orGrouping && !recGrouping) {
          return '&nbsp;'
        }

        if (level === 1 && recGrouping && !recGrouping.payment_indicator) {
          return '&nbsp;'
        }

        switch (field) {
          case 'paymentIndicator':
            switch (level) {
              case 0:
                return orGrouping ? `<span title="${orGrouping.payment_indicator_description}">${orGrouping.payment_indicator}</span>` : '&nbsp;'
              case 1:
                if (orGrouping && !recGrouping) {
                  return '<span title="ASC Deleted">DEL</span>'
                }
                if (orGrouping && recGrouping && recGrouping.payment_indicator === 'DEL') {
                  return '<span title="ASC Deleted">DEL</span>'
                }
                return recGrouping ? `<span title="${recGrouping.payment_indicator_description}">${recGrouping.payment_indicator}</span>` : '&nbsp;'
            }
            break
          case 'ascWeight':
            switch (level) {
              case 0:
                return orGrouping ? orGrouping.weight : '&nbsp;'
              case 1:
                if (orGrouping && !recGrouping) {
                  return '&nbsp;'
                }
                if (orGrouping && recGrouping && recGrouping.payment_indicator === 'DEL') {
                  return '&nbsp;'
                }
                return recGrouping && recGrouping ? recGrouping.weight : '&nbsp;'
            }
            break
          case 'ascPaymentRate':
            switch (level) {
              case 0:
                return orGrouping ? orGrouping.percentage * 100 + '%' : '&nbsp;'
              case 1:
                if (orGrouping && !recGrouping) {
                  return '&nbsp;'
                }
                if (orGrouping && recGrouping && recGrouping.payment_indicator === 'DEL') {
                  return '&nbsp;'
                }
                return recGrouping && recGrouping ? recGrouping.percentage * 100 + '%' : '&nbsp;'
            }
            break
          case 'ascPayment':
            switch (level) {
              case 0:
                return orGrouping && orGrouping.payment !== null ? '$' + orGrouping.payment.toFixed(2) : '&nbsp;'
              case 1:
                return recGrouping && recGrouping.payment !== null ? '$' + recGrouping.payment.toFixed(2) : '&nbsp;'
            }
            break
        }
      },
      getAPCData(cpt, field, level) {
        const row = this.worksheet.cptsGrouping.find(x => x.cptId === cpt.cptId)

        const orGrouping = row.ascRecommendationsCptsGrouping.find(x => x.codeLevel === 0)
        const recGrouping = row.ascRecommendationsCptsGrouping.find(x => x.codeLevel === 1)

        if (level === 1 && orGrouping && recGrouping && orGrouping.apc === recGrouping.apc && orGrouping.payment === recGrouping.payment) {
          return '&nbsp;'
        }

        if (level === 0 && !orGrouping) {
          return '&nbsp;'
        }

        if (level === 1 && !orGrouping && !recGrouping) {
          return '&nbsp;'
        }

        switch (field) {
          case 'apc':
            switch (level) {
              case 0:
                return orGrouping ? `<span title="${orGrouping.apcDescription}">${orGrouping.apc}</span>` : '&nbsp;'
              case 1:
                if (recGrouping && recGrouping.apc === -1) {
                  return '<span title="APC Deleted">DEL</span>'
                }
                return recGrouping ? `<span title="${recGrouping.apcDescription}">${recGrouping.apc}</span>` : '&nbsp;'
            }
            break
          case 'apcWeight':
            switch (level) {
              case 0:
                return orGrouping ? orGrouping.weight : '&nbsp;'
              case 1:
                if (recGrouping && recGrouping.apc === -1) {
                  return '&nbsp;'
                }
                return recGrouping && recGrouping ? recGrouping.weight : '&nbsp;'
            }
            break
          case 'apcPaymentRate':
            switch (level) {
              case 0:
                return orGrouping ? orGrouping.percentage * 100 + '%' : '&nbsp;'
              case 1:
                if (recGrouping && recGrouping.apc === -1) {
                  return '&nbsp;'
                }
                return recGrouping && recGrouping ? recGrouping.percentage * 100 + '%' : '&nbsp;'
            }
            break
          case 'statusIndicator':
            switch (level) {
              case 0:
                return orGrouping ? orGrouping.status_indicator : '&nbsp;'
              case 1:
                return recGrouping ? recGrouping.status_indicator : '&nbsp;'
            }
            break
          case 'apcPayment':
            switch (level) {
              case 0:
                return orGrouping && orGrouping.payment !== null ? '$' + orGrouping.payment.toFixed(2) : '&nbsp;'
              case 1:
                return recGrouping && recGrouping.payment !== null ? '$' + recGrouping.payment.toFixed(2) : '&nbsp;'
            }
            break
        }
      },
      cptModifiers(modifiers) {
        if (modifiers) {
          const newMods = modifiers
            .replaceAll('-', '')
            .split(' ')
            .filter(Boolean)
            .join(',')
          return newMods
        }
        return '&nbsp;'
      }
    },
    computed: {
      ...mapGetters('dropdowns/', ['GET_VALUEFROMKEY'])
    }
  }
</script>

<style scoped>
  .grouperFlags {
    font-style: italic;
    text-transform: uppercase;
  }

  >>> .tenPercent {
    width: 10%;
  }

  >>> .twentyPercent {
    width: 20%;
  }

  >>> .thirtyFivePercent {
    width: 30%;
  }

  >>> .highlight {
    /* background-color: rgba(255, 196, 12, 0.1) !important; */
    font-weight: bold;
  }

  >>> .highlight {
    border-left: 3px solid #f56c6c;
  }

  /* >>>.highlight:hover {
    background-color: rgba(255, 196, 12, 0.2) !important;
  } */

  >>> td {
    font-size: 13px !important;
  }

  >>> .feeScheduleHeader {
    color: #005695;
    border-top: 1px solid rgb(204, 204, 204);
    margin-top: 2px;
    padding-top: 2px;
    font-weight: normal;
    font-size: 11px;
  }

  >>> .feeScheduleData {
    border-top: 1px solid rgb(204, 204, 204);
    padding-top: 5px;
    font-size: 11px;
    position: absolute;
    bottom: 0px;
    margin: 10px 0px 10px 0px;
    width: 100%;
    color: #005695;
  }

  >>> #cptGrid.truCodeEnabled td {
    position: relative;
    padding-bottom: 32px;
  }
</style>
