<template>
  <div>
    <div style="margin: 10px 0px 50px 10px" v-for="(finding, index) in worksheet.findings" :key="index">
      <div class="findingHeader">{{finding.reasonDescription}}</div>
      <div style="display: grid; grid-template-columns: 15% 85%; align-items: center">
        <div style="grid-column: 1/2;">
          <h4 class="headerWithBorder" style="width: 80%">Action Taken</h4>
        </div>
        <HiaRichText style="grid-column: 2/2;" v-model="finding.findingDetails" title="Action Taken"></HiaRichText>

        <div style="grid-column: 1/2;">
          <h4 class="headerWithBorder" style="width: 80%">Location of Supporting Doc.</h4>
        </div>
        <HiaRichText style="grid-column: 2/2;" v-model="finding.supportingDoc" title="Location of Supporting Doc."></HiaRichText>

        <div style="grid-column: 1/2;">
          <h4 class="headerWithBorder" style="width: 80%">Coding Reference</h4>
        </div>

        <HiaRichText style="grid-column: 2/2; " v-model="finding.reference" title="Coding Reference"></HiaRichText>
      </div>
    </div>
    <div v-if="worksheet.findings.length === 0">
      <div style="font-style: italic; font-size: 14px; margin-top: 10px;">No Findings on Worksheet</div>
    </div>
  </div>
</template>

<script>
  import dateHelper from '@/mixins/date-helper'
  import { format, parseISO } from 'date-fns'
  import HiaRichText from '@/components/controls/HiaRichText'

  export default {
    name: 'coderInpatientFindings',
    mixins: [dateHelper],
    props: ['worksheet'],
    data() {
      return {
        format: format,
        parseISO: parseISO,
        disabled: true,
        loading: false
      }
    },
    components: {
      HiaRichText
    }
  }
</script>

<style scoped>
  >>>.rich-text {
    overflow-y: auto;
    max-height: 200px;
  }

  >>>.ellipsis {
    right: 25px;
  }

  .findingHeader {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    background-color: lightgray;
    border-radius: 3px;
    padding: 5px;
  }
</style>
