<template>
  <div id="projectLists">
    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">My Projects</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/my-projects' }">My Projects</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: `/my-projects/${project.id}` }">{{ project.projectID }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <div v-loading="loading" v-if="loading" style="height: 120px;" element-loading-spinner="atom-audit-loader"></div>
    <el-card v-show="!loading">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="Project" name="details" style="min-height: 400px;">
          <h4 class="headerWithBorder">Details</h4>
          <el-form v-if="!loading" size="mini" label-position="left" label-width="130px">
            <el-row :gutter="20">
              <el-col :lg="8" :md="12">
                <el-form-item size="mini" label="ID">
                  <div :class="'el-input el-input--mini is-disabled'">
                    <input id="projectID" :class="'el-input__inner'" :disabled="true" maxlength="200" type="text" :value="projectID" @change="putProjectId($event, 'projectID')" />
                  </div>
                </el-form-item>

                <el-form-item size="mini" label="Type">
                  <hia-el-select :disabled="true" v-model="projectTypeId" placeholder="Project Type" :clearable="false" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.projectTypes.list" v-on:input="updateProject()"></hia-el-select>
                </el-form-item>

                <el-form-item prop="name" label="Process">
                  <hia-el-select placeholder="Process" :disabled="true" :value="project.processId" clearable :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.projectProcesses.list" @input="updateProjectProcess($event)"></hia-el-select>
                </el-form-item>

                <el-form-item size="mini" label="Tags">
                  <el-select class="multiSelect" :disabled="true" size="mini" v-model="identifierSelectionsArray" filterable multiple collapse-tags placeholder="Tags" @visible-change="tagsSelectHideShow($event, project)" @remove-tag="tagsSelectRemoveTag($event, project)">
                    <el-option v-for="item in dropdowns.projectIdentifiers.list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.inactive"> </el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item size="mini" label="Description">
                  <textarea :disabled="true" style="width: 100%;" class="el-textarea__inner" type="textarea" :rows="2" id="comments" placeholder="Description" v-model.lazy="description" @change="updateProject('description')"></textarea>
                </el-form-item> -->
              </el-col>
              <el-col :lg="8" :md="12">
                <el-form-item size="mini" label="Start Date">
                  <el-date-picker :disabled="true" size="mini" type="date" v-model="startDate" id="startDate" format="MM/dd/yyyy" @change="updateProject('startDate')" :clearable="false"> </el-date-picker>
                </el-form-item>
                <el-form-item size="mini" label="End Date">
                  <el-date-picker :disabled="true" size="mini" type="date" v-model="endDate" id="endDate" format="MM/dd/yyyy" @change="updateProject('endDate')"> </el-date-picker>
                </el-form-item>
                <el-form-item size="mini" label="Status">
                  <hia-el-select :disabled="true" v-model="projectStatusId" placeholder="Project Status" :clearable="false" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.projectStatus.list" v-on:input="updateProject('projectStatusId')"></hia-el-select>
                </el-form-item>
                <el-form-item size="mini" label="Lead" prop="leadId">
                  <hia-el-select :disabled="true" v-model="leadId" placeholder="Lead" filterable clearable itemkey="key" label="value" :dropdowns="dropdowns.consultants.list" v-on:input="updateProject('leadId')"></hia-el-select>
                </el-form-item>
                <!-- <el-form-item size="mini" label="Project Color">
                  <el-color-picker :disabled="!projectManagement()" size="mini" v-model="projectColor" @change="updateProject(true)"></el-color-picker>
                </el-form-item> -->
              </el-col>
              <el-col :lg="8" :md="12">
                <el-form-item size="mini" label="Created By">
                  <el-input size="mini" id="userId" :value="getUserById(project.userId)" :disabled="true"> </el-input>
                </el-form-item>
                <el-form-item size="mini" label="Default Facility">
                  <hia-el-select :disabled="true" v-model="clientId" placeholder="Facility" clearable :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.clients.list" v-on:input="updateProject('clientId')"></hia-el-select>
                </el-form-item>
                <el-form-item v-if="['Professional Fee', 'Outpatient', 'Ambulatory'].includes(project.projectTypeName)" size="mini" label="Default Code Type">
                  <hia-el-select :disabled="true" @input="updateProject('defaultCodeType')" v-model="defaultCodeType" placeholder="Code Type" itemkey="key" label="value" :dropdowns="dropdowns.codeTypes.list" clearable filterable> </hia-el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <el-collapse v-model="activeNames" style="margin-top: 10px;">
            <!-- <el-collapse-item style="margin-top: 20px;" title="Project Tracking" name="tracking">
              <template slot="title">
                <span style="width: 100%;">
                  Project Tracking
                  <span class="codeTotalHeader"> {{ project.processId ? ` - ${getProcessName(project.processId)}` : ' - No Process Selected' }} </span>
                </span>
              </template>

              <el-row style="margin-top: 20px;" v-if="!loading">
                <el-col :span="24">
                  <v-client-table id="taskTable" class="hiaTable" :columns="['task', 'taskDate', 'isNA', 'modifiedBy', 'modifiedDate']" :data="project.projectTasks.filter(x => x.task.processId === selectedProcess)" :options="taskTableOptions">
                    <template slot="task" slot-scope="props">
                      {{ props.row.task ? props.row.task.name : '' }}
                    </template>
                    <template slot="taskDate" slot-scope="props">
                      <el-date-picker :disabled="props.row.isNA || !projectManagement()" style="max-width: 150px; padding: 0 0 0 5px; height: 20px; margin-right: 20px;" v-model="props.row.taskDate" @input="putProjectTask(props.row)" format="MM/dd/yyyy"></el-date-picker>
                    </template>
                    <template slot="isNA" slot-scope="props">
                      <el-checkbox :disabled="!projectManagement()" v-model="props.row.isNA" @input="putProjectTask(props.row)"></el-checkbox>
                    </template>
                    <template slot="modifiedBy" slot-scope="props">
                      {{ getUserById(props.row.modifiedBy) }}
                    </template>
                    <template slot="modifiedDate" slot-scope="props">
                      {{ props.row.modifiedDate ? format(parseISO(props.row.modifiedDate), 'M/d/yyyy hh:mm a') : '' }}
                      <i style="cursor: pointer" @click="showHistoryPopper($event, 'taskHistoryPopover', props.row)" v-if="props.row.taskHistory && props.row.taskHistory.length > 0" class="mdi mdi-history"></i>
                    </template>
                  </v-client-table>
                </el-col>
              </el-row>
            </el-collapse-item> -->

            <el-collapse-item title="Dashboard" name="dashboard">
              <div style="margin: 20px 0px 0px 0px;" v-if="!loading">
                <dashboards :drgTableIds="drgTableIds" :proRecordIds="proRecordIds" :ascTableIds="ascTableIds" :type="compDashboardType" :myProjects="true">
                  <template v-slot:filters>
                    &nbsp;
                    <!-- <el-col :span="2">
                      <i title="Filter dashboards based on record status" class="mdi mdi-filter-variant filterIcon" style="margin: 4px 0px 0px 10px; font-size: 18px;"></i>
                    </el-col>
                    <el-col :span="22">
                      <el-select class="multiSelect" style="" size="mini" v-model="statusFilters" filterable multiple @visible-change="statusSelectHideShow($event, project)" @remove-tag="statusSelectRemoveTag($event, project)">
                        <el-option v-for="item in dropdowns.reviewStatus.list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.inactive"> </el-option>
                      </el-select>
                    </el-col> -->
                  </template>
                  <template v-slot:stats>
                    &nbsp;
                    <!-- <center class="stats">
                      <el-badge class="item" :class="{ 'stats-loading': loading }" :value="compProjectStatistics.allRecords">
                        <el-tag type="primary" size="small">All Records</el-tag>
                      </el-badge>
                      <el-badge class="item" :class="{ 'stats-loading': loading }" :value="compProjectStatistics.notStarted">
                        <el-tag type="primary" size="small">Not Started</el-tag>
                      </el-badge>
                      <el-badge class="item" :class="{ 'stats-loading': loading }" :value="compProjectStatistics.inProgress">
                        <el-tag type="primary" size="small">In Progress</el-tag>
                      </el-badge>
                      <el-badge class="item" :class="{ 'stats-loading': loading }" :value="compProjectStatistics.readyForCoder">
                        <el-tag type="primary" size="small">Ready For Coder</el-tag>
                      </el-badge>
                      <el-badge class="item" :class="{ 'stats-loading': loading }" :value="compProjectStatistics.completed">
                        <el-tag type="primary" size="small">Completed</el-tag>
                      </el-badge>
                    </center> -->
                  </template>
                </dashboards>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>

        <el-tab-pane v-if="project.projectTypeName === 'Inpatient'" label="Inpatient Charts" name="Inpatient">
          <!-- <inpatientList></inpatientList> -->
          <coderInpatientList></coderInpatientList>
        </el-tab-pane>
        <el-tab-pane v-if="project.projectTypeName === 'Outpatient'" label="Outpatient Charts" name="Outpatient">
          <!-- <opList></opList> -->
          <coderOutpatientList></coderOutpatientList>
        </el-tab-pane>
        <el-tab-pane v-if="project.projectTypeName === 'Ambulatory'" label="Ambulatory Charts" name="Ambulatory">
          <!-- <opList></opList> -->
          <coderAmbulatoryList></coderAmbulatoryList>
        </el-tab-pane>
        <el-tab-pane v-if="project.projectTypeName === 'Professional Fee'" label="Pro Charts" name="Professional Fee">
          <!-- <proList></proList> -->
          <coderProList></coderProList>
        </el-tab-pane>
        <!-- <el-tab-pane label="Record Import" name="importNew" lazy>
          <recordImport></recordImport>
        </el-tab-pane> -->
      </el-tabs>
    </el-card>

    <el-popover ref="taskHistoryPopover" :title="`Change History for ${selectedTaskHistoryTask.name}`" placement="right" width="600" @after-enter="handleHidePopOver('taskHistoryPopover')">
      <div style="align-items: center;">
        <v-client-table id="taskTable" class="hiaTable" :columns="['event', 'date', 'user']" :data="selectedTaskHistoryRows" :options="taskTableOptions"> </v-client-table>
      </div>
    </el-popover>
    <el-popover ref="savePopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" :content="savePopoverContent" @after-enter="handleHidePopOver('savePopover')"> </el-popover>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
  import { createHelpers } from 'vuex-map-fields'
  // import InpatientList from '@/views/reviews/inpatient/inpatientList'
  import coderInpatientList from '@/views/coder/inpatient/coderInpatientList'
  import coderOutpatientList from '@/views/coder/outpatient/coderOutpatientList'
  import coderAmbulatoryList from '@/views/coder/ambulatory/coderAmbulatoryList'
  import coderProList from '@/views/coder/pro/coderProList'
  // import proList from '@/views/reviews/pro/proList'
  import dateHelper from '@/mixins/date-helper'
  // import recordImport from '@/views/projects/recordImport'
  import dashboards from '@/views/reports/dashboards'
  // import opList from '@/views/reviews/outpatient/opList'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import { format, parseISO } from 'date-fns'

  const { mapFields } = createHelpers({
    getterType: 'managedLists/projects/getField',
    mutationType: 'managedLists/projects/updateField'
  })

  export default {
    name: 'coderProject',
    mixins: [dateHelper, HiaAuthorization],
    data() {
      return {
        format: format,
        parseISO: parseISO,
        activeName: 'details',
        activeNames: ['tracking', 'dashboard'],
        statusFilters: [3, 4],
        backupStatusFilters: [],
        backupIdentifierSelectionsArray: [],
        deactivated: false,
        loading: false,
        selectedProcess: null,
        selectedTaskHistoryRows: [],
        selectedTaskHistoryTask: {},
        dropdownsNeeded: ['clients', 'projectIdentifiers', 'projectTypes', 'coders', 'consultants', 'codeTypes', 'outpatientGroupers', 'reviewStatus', 'projectStatus', 'projectProcesses'],
        localdateRange: [],
        taskTableOptions: {
          filterable: false,
          filterByColumn: false,
          sortable: ['name'],
          columnsClasses: {
            task: 'taskCol',
            taskDate: 'taskDateCol',
            isNA: 'isNACol',
            modifiedBy: 'modifiedByCol',
            modifiedDate: 'modifiedDateCol'
          },
          headings: {
            taskDate: 'Task Date',
            modifiedBy: 'Modified By',
            isNA: 'N/A',
            modifiedDate: 'Modified Date'
          },
          sortIcon: { is: 'none' }
        },
        savePopoverContent: ''
      }
    },
    methods: {
      ...mapActions('inpatientReviews/', ['GET_IPLIST_PROJECT_STATS']),
      ...mapActions('outpatientReviews/', ['GET_OPLIST_PROJECT_STATS']),
      ...mapActions('proReviews/', ['GET_PROLIST_PROJECT_STATS']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      ...mapActions('managedLists/projects/', ['LOAD_PROJECT', 'UPDATE_PROJECT', 'PUT_PROJECT_TASK', 'GET_PROJECT_DASHBOARD_IDS_STATS']),
      ...mapMutations('managedLists/projects/', ['SET_PROJECTPK', 'SET_PROJECT']),
      getUserById(userId) {
        let user = this.dropdowns.consultants.list.find(f => f.key === userId)
        if (user) {
          user = user.value
          return user
        }
        return user
      },
      initialize() {
        window.document.title = 'Project'
        this.loading = true
        const payload = {
          id: this.$route.params.id,
          statusFilters: this.statusFilters,
          isCoding: true
        }

        // we need to make sure that we load the project before we render the associated tabs for IP, OP and Pro Lists
        // because of the way the watchers work inside those components

        this.GET_DROPDOWNS(this.dropdownsNeeded).then(
          async () => {
            await Promise.all([this.LOAD_PROJECT(payload)]).then(response => {
              this.selectedProcess = this.project.processId
              if (!this.selectedProcess) {
                this.activeNames = this.activeNames.filter(x => x !== 'tracking')
              }

              this.$nextTick(() => {
                this.loading = false
                this.setActiveTabName()
              })
            })
          },
          error => {
            console.log(error)
            this.loading = false
          }
        )
      },
      handleTabClick(e) {
        if (this.activeName === 'details') {
          const payload = {
            id: this.id,
            statusFilters: this.statusFilters,
            projectTypeName: this.project.projectTypeName,
            isCoding: true
          }
          this.GET_PROJECT_DASHBOARD_IDS_STATS(payload)
        }
      },
      async putProjectTask(updatedRow) {
        const index = this.project.projectTasks.findIndex(x => x.projectsTaskId === updatedRow.projectsTaskId)

        if (updatedRow.isNA && updatedRow.taskDate) {
          try {
            await this.$confirm('This will clear the existing Task Date. Continue?', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            updatedRow.taskDate = null
          } catch (e) {
            updatedRow.isNA = !updatedRow.isNA
            this.$set(this.project.projectTasks, index, updatedRow)
            return
          }
        }

        // we found a bug where element's datepicker X button calls this method twice
        // so the following code prevents the network call from happening twice
        const existingRow = this.project.projectTasks[index]
        if (JSON.stringify(updatedRow) === JSON.stringify(existingRow)) {
          return
        }

        this.$set(this.project.projectTasks, index, updatedRow)

        try {
          await this.PUT_PROJECT_TASK(updatedRow)
          this.$message({
            message: 'Project Task Saved',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'Error Editing Project Task',
            type: 'error'
          })
        }
      },
      setActiveTabName() {
        // because of keep alive, we may need to reset the active tab if the user goes from in IP project to an OP or Pro project
        if (this.activeName !== 'details' && this.activeName !== this.project.projectTypeName) {
          this.activeName = 'details'
        }
        if (this.$route.query.type) {
          if (this.project.projectTypeName === 'Ambulatory') {
            this.activeName = 'Ambulatory'
          } else {
            this.activeName = this.$route.query.type
          }
        }
      },
      async updateProjectProcess(val) {
        this.project.processId = parseInt(val)
        await this.updateProject('taskTemplate')
        this.selectedProcess = this.project.processId
      },
      async updateProject(field) {
        try {
          if (field === 'projectStatusId') {
            switch (this.project.projectStatusId) {
              case 1:
                this.project.projectColor = '#003169'
                break
              case 2:
                this.project.projectColor = '#fec41f'
                break
              case 3:
                this.project.projectColor = '#63b344'
                break
            }
          }
          await this.UPDATE_PROJECT(this.project)

          if (this.project.processId && !this.activeNames.some(x => x === 'tracking')) {
            this.activeNames.push('tracking')
          }

          this.$message({
            message: 'Project Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: err.message ? `Project Update Error: ${err.message}` : 'Project Update Error',
            type: 'error'
          })
        }
      },
      getProcessName(processId) {
        return this.GET_VALUEFROMKEY('projectProcesses', processId)
      },
      showHistoryPopper(e, refName, row) {
        this.selectedTaskHistoryRows = []

        // copy the history and stick the current row in as the first history item
        // so that the popover displays current + all history
        const history = JSON.parse(JSON.stringify(row.taskHistory))
        history.unshift(row)

        this.selectedTaskHistoryTask = row.task

        for (let i = 0; i < history.length; i++) {
          const currentRow = history[i]
          const olderRow = history[i + 1]

          if (olderRow) {
            if (currentRow.isNA !== olderRow.isNA) {
              if (currentRow.isNA) {
                this.selectedTaskHistoryRows.push({
                  event: 'N/A was checked',
                  date: format(parseISO(currentRow.modifiedDate), 'M/d/yyyy hh:mm a'),
                  user: this.getUserById(currentRow.modifiedBy)
                })
              } else {
                this.selectedTaskHistoryRows.push({
                  event: 'N/A was unchecked',
                  date: format(parseISO(currentRow.modifiedDate), 'M/d/yyyy hh:mm a'),
                  user: this.getUserById(currentRow.modifiedBy)
                })
              }
            }

            if (currentRow.taskDate !== olderRow.taskDate) {
              this.selectedTaskHistoryRows.push({
                event: `Task Date was changed from ${olderRow.taskDate ? format(parseISO(olderRow.taskDate), 'M/d/yyyy') : 'blank'} to ${currentRow.taskDate ? format(parseISO(currentRow.taskDate), 'M/d/yyyy') : 'blank'}`,
                date: format(parseISO(currentRow.modifiedDate), 'M/d/yyyy hh:mm a'),
                user: this.getUserById(currentRow.modifiedBy)
              })
            }
          } else {
            if (currentRow.isNA) {
              this.selectedTaskHistoryRows.push({
                event: 'N/A was checked',
                date: format(parseISO(currentRow.modifiedDate), 'M/d/yyyy hh:mm a'),
                user: this.getUserById(currentRow.modifiedBy)
              })
            } else {
              this.selectedTaskHistoryRows.push({
                event: `Task Date was set to ${currentRow.taskDate ? format(parseISO(currentRow.taskDate), 'M/d/yyyy') : 'blank'}`,
                date: format(parseISO(currentRow.modifiedDate), 'M/d/yyyy hh:mm a'),
                user: this.getUserById(currentRow.modifiedBy)
              })
            }
          }
        }

        const popper = this.$refs[refName]
        popper.doDestroy()
        popper.doClose()

        this.$nextTick(() => {
          this.popperClickTarget = e.target
          popper.doDestroy()
          popper.referenceElm = e.target
          this.isOpen = true
          popper.doShow()
        })
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = e => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      },
      statusSelectHideShow(show) {
        if (show) {
          this.backupStatusFilters = this.statusFilters
        } else {
          if (JSON.stringify(this.backupStatusFilters) !== JSON.stringify(this.statusFilters)) {
            this.handleTabClick()
          }
        }
      },
      statusSelectRemoveTag() {
        this.handleTabClick()
      },
      tagsSelectHideShow(show, project) {
        if (show) {
          this.backupIdentifierSelectionsArray = this.identifierSelectionsArray
        } else {
          if (JSON.stringify(this.backupIdentifierSelectionsArray) !== JSON.stringify(this.identifierSelectionsArray)) {
            this.identifiersOnChange(project)
          }
        }
      },
      tagsSelectRemoveTag(val, project) {
        this.identifiersOnChange(project)
      },
      identifiersOnChange(project) {
        project.identifierSelectionsArray.forEach(element => {
          // if element doesn't exist in, add it with add flag
          const matchedElements = project.identifierSelections.some(function(i) {
            return i.identifierID === element
          })
          if (!matchedElements) {
            const newValue = {
              id: 0,
              identifierID: element,
              add: true
            }

            project.identifierSelections.push(newValue)
          }
        })

        project.identifierSelections.forEach(element => {
          // if element doesn't exists in Array, set deleted flag
          if (!project.identifierSelectionsArray.includes(element.identifierID)) {
            element.delete = true
          }
        })

        this.updateProject(true)
      },
      putProjectId(event, field) {
        if (!event.target.value) {
          this.savePopoverContent = 'Project ID must always contain a value.'
          this.openSavePopover(event)
          event.target.value = this.project.projectID
          return false
        }
        this.project.projectID = event.target.value
        this.updateProject(field)
      },
      openSavePopover(e) {
        if (!e) {
          return
        }

        const popper = this.$refs.savePopover

        popper.doDestroy()
        popper.doClose()

        this.$nextTick(() => {
          this.popperClickTarget = e.target
          popper.doDestroy()
          popper.referenceElm = this.popperClickTarget
          this.isOpen = true
          popper.doShow()
        })
      }
    },
    // activated: function () {
    //   window.document.title = 'Projects'
    //   this.isActivated = true
    //   if (parseInt(this.$route.params.id) !== this.project.id) {
    //     this.initialize()
    //   } else {
    //     // may need to reset the project id in vuex in case it was cleared elsewhere
    //     this.SET_PROJECTPK(this.project.id)
    //   }
    // },
    deactivated: function() {
      this.loading = true
      this.deactivated = true
    },
    created: function() {
      this.initialize()
    },
    activated: function() {
      if (this.deactivated) {
        this.initialize()
      }
      this.deactivated = false
    },
    components: {
      coderInpatientList,
      coderOutpatientList,
      coderAmbulatoryList,
      coderProList,
      dashboards
    },
    computed: {
      ...mapGetters('dropdowns', ['GET_VALUEFROMKEY']),
      ...mapState('inpatientReviews/', ['ipProjectStatistics']),
      ...mapState('outpatientReviews/', ['opProjectStatistics']),
      ...mapState('proReviews/', ['proProjectStatistics']),
      ...mapState('reports/', ['reports']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('managedLists/projects/', ['project', 'projectLoading', 'drgTableIds', 'proRecordIds', 'ascTableIds']),
      ...mapFields(['project.clientId', 'project.startDate', 'project.endDate', 'project.identifierId', 'project.coderId', 'project.id', 'project.projectID', 'project.projectTypeId', 'project.leadId', 'project.identifierSelectionsArray', 'project.identifierSelections', 'project.defaultCodeType', 'project.description', 'project.outpatientGrouperId', 'project.projectColor', 'project.projectStatusId', 'project.coderReviewedDate', 'project.providerReviewedDate', 'project.responseCompleteDate']),
      compDashboardType() {
        if (this.project.projectTypeName === 'Inpatient') {
          return 'ip'
        }
        if (this.project.projectTypeName === 'Outpatient') {
          return 'op'
        }
        if (this.project.projectTypeName === 'Professional Fee') {
          return 'pro'
        }
        if (this.project.projectTypeName === 'Ambulatory') {
          return 'asc'
        }
        return null
      },
      isOutpatient() {
        if (this.dropdowns && this.dropdowns.projectTypes) {
          return this.dropdowns.projectTypes.list.some(x => x.value === 'Outpatient' && x.key === this.projectTypeId)
        }
        return false
      },
      compProjectStatistics() {
        if (this.project.projectTypeName === 'Inpatient') {
          return this.ipProjectStatistics
        }
        if (this.project.projectTypeName === 'Outpatient') {
          return this.opProjectStatistics
        }
        if (this.project.projectTypeName === 'Professional Fee') {
          return this.proProjectStatistics
        }
        if (this.project.projectTypeName === 'Ambulatory') {
          return this.opProjectStatistics
        }
        return {}
      }
    }
  }
</script>

<style scoped>
  #comments::placeholder {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
  }

  .projectButtons {
    margin-top: 40px;
  }

  .projectButtons button {
    margin-left: 10px;
  }

  >>> #ipSummaryTitle {
    display: none;
  }

  .multiSelect >>> .el-input__inner {
    min-height: 30px !important;
  }

  #taskTable >>> .VuePagination {
    display: none;
  }

  >>> .taskCol {
    width: 20%;
  }

  >>> .taskDateCol {
    width: 15%;
  }

  >>> .isNACol {
    width: 10%;
  }

  >>> .modifiedByCol {
    width: 20%;
  }

  >>> .modifiedDatecol {
    width: 20%;
  }

  @media screen and (max-width: 1310px) and (min-width: 0px) {
    >>> .taskCol {
      width: 25%;
    }

    >>> .taskDateCol {
      width: 25%;
    }

    >>> .isNACol {
      width: 20%;
    }

    >>> .modifiedByCol {
      width: 15%;
    }

    >>> .modifiedDatecol {
      width: 15%;
    }
  }
</style>
