<template>
  <div>
    <el-row class="header-row">
      <el-col :span="16">
        <h3 class="el-page-header">Record Detail</h3>
        <div>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/my-projects' }">My Projects</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: `/my-projects/${proWorksheet.projectIdentifier}` }"> {{ proWorksheet.projectId }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: `/my-projects/pro/${$route.params.proRecordId}` }">Record Detail</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30" v-if="loading">
      <el-col :span="24">
        <div v-loading="loading" element-loading-spinner="atom-audit-loader"></div>
      </el-col>
    </el-row>
    <el-card class="coderContainerCard" style="min-height: 100px;">
      <el-row :gutter="30" v-if="!loading">
        <el-col :span="16">
          <el-button class="hiaButton pull-right" icon="el-icon-document" size="mini" @click="openWorksheet" plain round>Worksheet</el-button>
          <h5>Audit Results</h5>
          <coderProDemographics style="margin: 0px 0px 20px 10px;" :worksheet="proWorksheet" class="collapseContent"></coderProDemographics>
          <el-collapse id="reviewAccordion" v-model="activeNames">
            <el-collapse-item name="dxs">
              <template slot="title">
                <span style="width: 100%;">
                  Diagnosis Codes
                  <span class="codeTotalHeader"> - {{ proWorksheet.dxs.length }} Total</span>
                </span>
              </template>
              <coderProDxs :worksheet="proWorksheet"></coderProDxs>
            </el-collapse-item>
            <el-collapse-item name="cpts">
              <template slot="title">
                <span style="width: 100%;">
                  CPT Codes
                  <span class="codeTotalHeader"> - {{ proWorksheet.cpts.length }} Total</span>
                </span>
              </template>
              <coderProCpts :worksheet="proWorksheet"></coderProCpts>
            </el-collapse-item>
            <el-collapse-item title="EM Scoring" name="emScoring">
              <div class="worksheet">
                <table>
                  <tr>
                    <td width="95%">
                      <ProWorksheetHistory class="subTable" v-if="!proWorksheet.decisionMaking2021 && !proWorksheet.decisionMaking2023" :scoring="proWorksheet.scoring" :worksheet="proWorksheet"> </ProWorksheetHistory>
                      <ProWorksheetPhysicalExam class="subTable" v-if="!proWorksheet.decisionMaking2021 && !proWorksheet.decisionMaking2023" :scoring="proWorksheet.scoring" :worksheet="proWorksheet"></ProWorksheetPhysicalExam>
                      <ProWorksheetMDM class="subTable" v-if="!proWorksheet.decisionMaking2021 && !proWorksheet.decisionMaking2023" :scoring="proWorksheet.scoring" :worksheet="proWorksheet"> </ProWorksheetMDM>
                      <ProWorksheetMDM2021 class="subTable" v-if="proWorksheet.decisionMaking2021 || proWorksheet.decisionMaking2023" :scoring="proWorksheet.scoring" :worksheet="proWorksheet"> </ProWorksheetMDM2021>
                      <ProWorksheetHistory class="subTable" v-if="proWorksheet.decisionMaking2021 || proWorksheet.decisionMaking2023" :scoring="proWorksheet.scoring" :worksheet="proWorksheet"> </ProWorksheetHistory>
                      <ProWorksheetPhysicalExam class="subTable" v-if="proWorksheet.decisionMaking2021 || proWorksheet.decisionMaking2023" :scoring="proWorksheet.scoring" :worksheet="proWorksheet"></ProWorksheetPhysicalExam>
                    </td>
                  </tr>
                </table>
              </div>
            </el-collapse-item>
            <el-collapse-item title="Coding Findings" name="auditFindings">
              <coderProCodingFindings :worksheet="proWorksheet"></coderProCodingFindings>
            </el-collapse-item>

            <!-- <el-collapse-item title="Compliance Findings" name="complianceFindings">
              <coderProComplianceFindings :worksheet="proWorksheet"></coderProComplianceFindings>
            </el-collapse-item> -->
          </el-collapse>
        </el-col>
        <el-col :span="8" class="sticky">
          <h5>Responses</h5>
          <el-form ref="form" id="form" label-position="left" label-width="200px" size="mini" :key="1" style="margin-top: 0px;margin-left: 10px;">
            <el-form-item v-if="proWorksheet.auditResult" size="mini" label="Audit Result">
              <div style="margin: 6px 0px 0px 15px;">{{ proWorksheet.auditResult }}</div>
            </el-form-item>
            <el-form-item v-if="proWorksheet.responseNeeded" size="mini" label="Response Needed">
              <div style="margin: 6px 0px 0px 15px;">{{ proWorksheet.responseNeeded }}</div>
            </el-form-item>
            <el-form-item v-if="proWorksheet.finalOutcome" size="mini" label="Final Outcome">
              <div style="margin: 6px 0px 0px 15px;">{{ proWorksheet.finalOutcome }}</div>
            </el-form-item>
          </el-form>
          <coderProResponses :proRecordId="proWorksheet.proRecordId" @dirty="unsavedChanges = $event" @GET_PRO_WORKSHEET="GET_PRO_WORKSHEET" :worksheet="proWorksheet"></coderProResponses>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import coderProDemographics from './coderProDemographics'
  import coderProDxs from './coderProDxs'
  import coderProCpts from './coderProCpts'
  import coderProCodingFindings from './coderProCodingFindings'
  // import coderProComplianceFindings from './coderProComplianceFindings'
  import coderProResponses from './coderProResponses'
  import ProWorksheetPhysicalExam from '@/views/reviews/pro/worksheets/ProWorksheetPhysicalExam'
  import ProWorksheetMDM from '@/views/reviews/pro/worksheets/ProWorksheetMDM'
  import ProWorksheetHistory from '@/views/reviews/pro/worksheets/ProWorksheetHistory'
  import ProWorksheetMDM2021 from '@/views/reviews/pro/worksheets/ProWorksheetMDM2021'

  export default {
    name: 'CoderProReview',
    data: function() {
      return {
        loading: true,
        unsavedChanges: false,
        activeNames: ['dxs', 'cpts', 'emScoring', 'auditFindings']
      }
    },
    components: {
      coderProDemographics,
      coderProDxs,
      coderProCpts,
      coderProCodingFindings,
      // coderProComplianceFindings,
      coderProResponses,
      ProWorksheetHistory,
      ProWorksheetPhysicalExam,
      ProWorksheetMDM,
      ProWorksheetMDM2021
    },
    methods: {
      ...mapActions('coder/', ['GET_PRO_WORKSHEET']),
      ...mapMutations('rebuttals/', ['SET_REBUTTAL']),
      ...mapActions('dropdowns', ['GET_DROPDOWNS']),
      async getWorksheet() {
        try {
          this.loading = true
          await this.GET_PRO_WORKSHEET(this.$route.params.proRecordId)
          // update vuex rebuttal - rebuttals are managed in vuex after this call
          this.SET_REBUTTAL(this.proWorksheet.rebuttal)
          this.loading = false
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Error getting coder worksheet',
            type: 'error'
          })
          console.log(e)
        }
      },
      openWorksheet() {
        const cacheBuster = new Date().getTime()
        this.worksheetWindow = window.open(`/my-projects/pro/worksheet/${this.proWorksheet.proRecordId}?dt=${cacheBuster}&fullscreen=true`, 'worksheet', 'height=850,width=1600')
      },
      confirmLeave() {
        return window.confirm('Changes you made may not be saved.')
      },
      confirmStayInDirtyForm() {
        return this.unsavedChanges && !this.confirmLeave()
      },
      beforeWindowUnload(e) {
        if (this.confirmStayInDirtyForm()) {
          e.preventDefault()
          e.returnValue = ''
        }
      }
    },
    beforeRouteLeave(to, from, next) {
      if (this.confirmStayInDirtyForm()) {
        next(false)
      } else {
        // Navigate to next view
        next()
      }
    },
    created: async function() {
      this.loading = true
      await this.getWorksheet()
      this.loading = false
      window.addEventListener('beforeunload', this.beforeWindowUnload)
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
    computed: {
      ...mapState('coder/', ['proWorksheet'])
    },
    watch: {}
  }
</script>

<style scoped>
  h5 {
    margin: 0px 0px 20px 10px;
    font-size: 14px;
  }

  .sticky {
    position: sticky;
    top: 10px;
  }

  >>> .hiaTable tr td {
    border: none !important;
  }

  >>> .hiaTable th {
    border: none !important;
  }

  >>> .coderContainerCard {
    overflow: unset;
  }

  >>> .scoringHeader {
    color: rgba(0, 0, 0, 0.9);
    background-color: lightgray;
    font-weight: 500;
  }

  >>> .subTable table {
    margin-bottom: 4px;
  }
</style>
