<template>
  <div>
    <el-row class="header-row">
      <el-col :span="16">
        <h3 class="el-page-header">My Records</h3>
        <div>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path: '/coder/inpatient' }">Coder Results</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>
    <div v-loading="loading" v-if="loading" element-loading-spinner="atom-audit-loader"></div>
    <el-card style="min-height: 100px;">
      <el-tabs v-model="activeTab" v-if="!loading">
        <el-tab-pane label="Inpatient" name="inpatient">
          <coderInpatientList></coderInpatientList>
        </el-tab-pane>
        <el-tab-pane label="Outpatient" name="outpatient">
          <coderOutpatientList></coderOutpatientList>
        </el-tab-pane>
        <el-tab-pane label="Pro" name="pro">
          <coderProList></coderProList>
        </el-tab-pane>
        <el-tab-pane label="Ambulatory" name="ambulatory">
          <coderAmbulatoryList></coderAmbulatoryList>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
  import coderInpatientList from './inpatient/coderInpatientList'
  import coderOutpatientList from './outpatient/coderOutpatientList'
  import coderAmbulatoryList from './ambulatory/coderAmbulatoryList'
  import coderProList from './pro/coderProList'
  import { mapActions } from 'vuex'

  export default {
    name: 'CoderHome',
    data: function () {
      return {
        activeTab: 'inpatient',
        loading: false
      }
    },
    components: {
      coderInpatientList,
      coderOutpatientList,
      coderAmbulatoryList,
      coderProList
    },
    methods: {
      ...mapActions('coder/', ['GET_CODER_IP_EXISTS', 'GET_CODER_PRO_EXISTS', 'GET_CODER_OP_EXISTS'])
    },
    created: async function () {
      this.loading = true
      try {
        const counts = await Promise.all([this.GET_CODER_IP_EXISTS(), this.GET_CODER_PRO_EXISTS(), this.GET_CODER_OP_EXISTS()])
        this.loading = false

        if (this.$route.query.type) {
          this.activeTab = this.$route.query.type
          return
        }

        if (counts[0] && counts[0].recordType === 'IP' && counts[0].count > 0) {
          this.activeTab = 'inpatient'
          return
        }
        if (counts[1] && counts[1].recordType === 'PRO' && counts[1].count > 0) {
          this.activeTab = 'pro'
          return
        }
        if (counts[2] && counts[2].recordType === 'OP' && counts[2].count > 0) {
          this.activeTab = 'outpatient'
          return
        }
      } catch (e) {
        this.loading = false
        this.$message({
          message: 'Error getting coder list',
          type: 'error'
        })
      }
    }
  }
</script>
