<template>
  <div>

    <v-client-table key="cptGrid" class="hiaTable" ref="cptGrid" id="cptGrid" :class="{'truCodeEnabled': isTrucodeEnabled()}" :data="worksheet.cpts" :columns="pxColumns"
      :options="options" uniqueKey="cptId">

      <template slot="h__originalCPT">
        <div>Orig. CPT</div>
        <div v-if="isTrucodeEnabled()" class="feeScheduleHeader">
          <div class="feeScheduleHeaderChild">Work RVU</div>
        </div>
      </template>

      <template slot="h__originalModifiers">
        <div>Mods</div>
        <div v-if="isTrucodeEnabled()" class="feeScheduleHeader">
          <div class="feeScheduleHeaderChild">Global Surgery</div>
        </div>
      </template>

      <template slot="h__origDesc">
        <div>Desc</div>
        <div v-if="isTrucodeEnabled()" style="display: flex" class="feeScheduleHeader">
          <div class="feeScheduleHeaderChild" style="flex: 1 1">
            Assistant at Surgery
          </div>
          <div class="feeScheduleHeaderChild" style="flex: 1 1; margin-left: 10px;">
            Co-Surgeons
          </div>
        </div>
      </template>

      <template slot="h__recommendedCpt">
        <div>Rec. CPT</div>
        <div v-if="isTrucodeEnabled()" class="feeScheduleHeader">
          <div class="feeScheduleHeaderChild">Rec. Work RVU</div>
        </div>
      </template>

      <template slot="h__recommendedModifiers">
        <div>Mods</div>
        <div v-if="isTrucodeEnabled()" class="feeScheduleHeader">
          <div class="feeScheduleHeaderChild">Rec. Global Surgery</div>
        </div>
      </template>

      <template slot="h__recDesc">
        <div>Desc</div>
        <div v-if="isTrucodeEnabled()" style="display: flex" class="feeScheduleHeader">
          <div class="feeScheduleHeaderChild" style="flex: 1 1">
            Rec. Asst. at Surgery
          </div>
          <div class="feeScheduleHeaderChild" style="flex: 1 1; margin-left: 10px;">
            Rec. Co-Surgeons
          </div>
        </div>
      </template>

      <template slot="originalCPT" slot-scope="props">
        <div>
          <!-- <el-input style="width: 112px;" :inputId="`originalCPT_${props.index}`" class="noRightBorder borderLeftRadius" v-model="props.row.originalCPT" :disabled="disabled">
          </el-input> -->
          {{props.row.originalCPT}}
        </div>
        <div class="feeScheduleData" title="Original Work RVU" v-if="isTrucodeEnabled()" v-html="getPhysicianFeeScheduleData(props.row, 'workRvu', 0)"></div>
      </template>

      <template slot="originalModifiers" slot-scope="props">
        <div v-html="cptModifiers(props.row.originalModifiers)"></div>
        <div :title="`Original Global Surgery\n${getGlobalSurgeryTitle(getPhysicianFeeScheduleData(props.row, 'globalSurgery', 0))}`" v-if="isTrucodeEnabled()"
          class="feeScheduleData" v-html="getPhysicianFeeScheduleData(props.row, 'globalSurgery', 0)">
        </div>
      </template>

      <template slot="origDesc" slot-scope="props">
        <div style="text-transform: lowercase;" :title="props.row.origDesc">{{truncateDescription(props.row.origDesc)}}
          <span v-if="props.row.originalGrouperFlags" :title="getGrouperflagsTitle(props.row.originalGrouperFlags)"
            class="grouperFlags">({{props.row.originalGrouperFlags}})</span>
        </div>
        <div v-if="isTrucodeEnabled()" style="display: flex;">
          <div style="flex: 1 1;">
            <div title="Original Assistant at Surgery" class="feeScheduleData" v-if="isTrucodeEnabled()" v-html="getPhysicianFeeScheduleData(props.row,
                'assistantAtSurgery', 0)"></div>
          </div>
          <div style="flex: 1 1;">
            <div title="Original Co-Surgeons" style="flex: 1 1;" v-if="isTrucodeEnabled()">
              <div style="padding-left: 5px;" class="feeScheduleData" v-html="getPhysicianFeeScheduleData(props.row, 'coSurgeons', 0)"></div>
            </div>
          </div>
        </div>
      </template>

      <template slot="recommendedCpt" slot-scope="props">
        <div>
          <!-- <el-input disabled style="width: 112px;" :placeholder="props.row.originalCPT" :inputId="`recommendedCpt_${props.index}`" :class="{deleteCode: props.row.deleteCode}"
            v-model="props.row.recommendedCpt" @ellipsisClick="emitEllipsisClick(props.row.recommendedCpt)" @focus="emitFocusedCode(props.row.recommendedCpt)">
          </el-input> -->
          {{ props.row.originalDeleted ? 'DEL' : props.row.recommendedCpt}}
        </div>
        <div title="Recommended Work RVU" v-if="isTrucodeEnabled()" class="feeScheduleData" v-html="getPhysicianFeeScheduleData(props.row, 'workRvu', 1)"></div>
      </template>

      <template slot="recommendedModifiers" slot-scope="props">
        <div v-html="cptModifiers(props.row.recommendedModifiers)"></div>
        <!-- <div v-if="isTrucodeEnabled()" class="feeScheduleHeader">
          <div class="feeScheduleHeaderChild">Global Surgery</div>
        </div> -->
        <div :title="`Original Global Surgery\n${getGlobalSurgeryTitle(getPhysicianFeeScheduleData(props.row, 'globalSurgery', 1))}`" v-if="isTrucodeEnabled()"
          class="feeScheduleData" v-html="getPhysicianFeeScheduleData(props.row, 'globalSurgery', 0)">
        </div>
      </template>

      <template slot="recDesc" slot-scope="props">
        <div style="text-transform: lowercase;" :title="props.row.originalDeleted ? 'Delete Code' : props.row.recDesc">
          {{props.row.originalDeleted ? 'Delete Code' : truncateDescription(props.row.recDesc)}}

          <span :title="getGrouperflagsTitle(props.row.recommendedGrouperFlags)"
            v-if="((props.row.recommendedDx && props.row.recommendedGrouperFlags) || (props.row.recommendedGrouperFlags && props.row.recommendedGrouperFlags !== props.row.originalGrouperFlags))"
            class="grouperFlags">({{props.row.recommendedGrouperFlags}})</span>
        </div>
        <div v-if="isTrucodeEnabled()" style="display: flex;" class="feeScheduleData">
          <div title="Recommended Assistant at Surgery" style="flex: 1 1;" v-if="isTrucodeEnabled()" v-html="getPhysicianFeeScheduleData(props.row,
                'assistantAtSurgery', 1)"></div>
          <div title="Recommended Co-Surgeons" style="flex: 1 1; margin-left: 10px;" v-if="isTrucodeEnabled()"
            v-html="getPhysicianFeeScheduleData(props.row, 'coSurgeons', 1)">
          </div>
        </div>
      </template>

      <template slot="reasonForChangeDescription" slot-scope="props">
        <!-- <el-input style="width: 82%" :disabled="disabled" @input="putInpatientPx(props.row, $event)" v-model="props.row.reasonForChange" placeholder="Reason for Change">
        </el-input> -->

        {{props.row.reasonForChangeDescription}}
      </template>

      <template slot="response" slot-scope="props">
        <el-button :disabled="readOnly()" style="margin-right: 5px;" size="mini" v-if="props.row.recommendedCpt || props.row.originalDeleted" type="text"
          @click="addResponse(props.row)">Respond
        </el-button>
      </template>

    </v-client-table>

  </div>
</template>

<script>
  import dateHelper from '@/mixins/date-helper'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import { format, parseISO } from 'date-fns'
  import { mapMutations } from 'vuex'

  export default {
    name: 'coderInpatientPxs',
    mixins: [dateHelper, HiaAuthorization],
    props: ['worksheet'],
    data() {
      return {
        format: format,
        parseISO: parseISO,
        disabled: true,
        loading: false,
        pxColumns: ['originalCPT', 'originalModifiers', 'origDesc', 'recommendedCpt', 'recommendedModifiers', 'recDesc', 'reasonForChangeDescription', 'response'],
        options: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          queue: [],
          networkFunction: null,
          perPage: 100,
          rowClassCallback: row => {
            if (row.recommendedCpt || row.deleteOriginal) {
              return 'highlight'
            }
            return ''
          },
          columnsClasses: {
            originalCPT: 'tenPercent',
            origDesc: 'thirtyFivePercent',
            recommendedCpt: 'tenPercent',
            recDescription: 'thirtyFivePercent',
            reasonForChange: 'tenPercent'
          },
          headings: {
            originalCPT: 'Original CPT',
            origDesc: 'Desc.',
            recommendedCpt: 'Recommended CPT',
            recDesc: 'Desc.',
            reasonForChange: 'Reason For Change',
            response: '',
            reasonForChangeDescription: 'Reason For Change'
          }
        }
      }
    },
    methods: {
      ...mapMutations('coder/', ['SET_RESPONSE_ROW']),
      truncateDescription(description) {
        const max = 100
        if (description && description.length > max) {
          return description.substring(0, max) + '...'
        }
        return description
      },
      getGrouperflagsTitle(flagList) {
        if (!flagList) {
          return
        }

        const flagMap = {
          AD: 'Affects DRG',
          CC: 'Complication/Comorbidity',
          HC: 'Hospital-Acquired Condition',
          MC: 'Complication/Comorbidity',
          R: 'Affects Risk of Mortality',
          S: 'Affects Severity of Illness',
          AP: 'Affects Patient Safety Indicators',
          EP: 'Excludes Patient Safety Indicators',
          AQ: 'Affects Quality Measures',
          EQ: 'Excludes Quality Measures',
          OR: 'Operative Procedure',
          NOR: 'Non-Operative Procedure'
        }

        const flags = flagList.split(',')
        const title = []
        flags.forEach(x => {
          title.push(`${x}: ${flagMap[x]}`)
        })

        return title.join('\n')
      },
      addResponse(row) {
        this.SET_RESPONSE_ROW(row)
      },
      getPhysicianFeeScheduleData(cpt, field, level) {
        let data = null
        switch (level) {
          case 0:
            data = cpt.originalCPT ? this.worksheet.proRecordCptsPhysicianFeeSchedule.find(f => f.cptId === cpt.cptId && f.codeLevel === level) : null
            break
          case 1:
            data = cpt.recommendedCpt ? this.worksheet.proRecordCptsPhysicianFeeSchedule.find(f => f.cptId === cpt.cptId && f.codeLevel === level) : null
            break
          case 2:
            data = cpt.level2Cpt ? this.worksheet.proRecordCptsPhysicianFeeSchedule.find(f => f.cptId === cpt.cptId && f.codeLevel === level) : null
            break
        }
        return data ? data[field] : '&nbsp;'
      },
      getGlobalSurgeryTitle(val) {
        const map = {
          '000': '000 - Endoscopic or minor procedure with related preoperative and postoperative relative values on the day of the procedure only included in the fee schedule payment amount; evaluation and management services on the day of the procedure generally not payable.',
          '010': '010 - Minor procedure with preoperative relative values on the day of the procedure and postoperative relative values during a 10 day postoperative period included in the fee schedule amount; evaluation and management services on the day of the procedure and during the 10-day postoperative period generally not payable.',
          '090': '090 - Major surgery with a 1-day preoperative period and 90-day postoperative period included in the fee schedule amount.',
          MMM: 'MMM - Maternity codes; usual global period does not apply.',
          XXX: 'XXX - The global concept does not apply to the code.',
          YYY: 'YYY - The carrier is to determine whether the global concept applies and establishes postoperative period, if appropriate, at time of pricing.',
          ZZZ: 'ZZZ - The code is related to another service and is always included in the global period of the other service.'
        }
        return map[val]
      },
      cptModifiers(modifiers) {
        if (modifiers) {
          const newMods = modifiers.replaceAll('-', '').split(' ').filter(Boolean).join(',')
          return newMods
        }
        return '&nbsp;'
      }
    }
  }
</script>

<style scoped>
  .grouperFlags {
    font-style: italic;
    text-transform: uppercase;
  }

  >>>.tenPercent {
    width: 10%;
  }

  >>>.twentyPercent {
    width: 20%;
  }

  >>>.thirtyFivePercent {
    width: 30%;
  }

  >>>.highlight {
    /* background-color: rgba(255, 196, 12, 0.1) !important; */
    font-weight: bold;
  }

  >>>.highlight {
    border-left: 3px solid #f56c6c;
  }

  /* >>>.highlight:hover {
    background-color: rgba(255, 196, 12, 0.2) !important;
  } */

  >>>td {
    font-size: 13px !important;
  }

  >>>.feeScheduleHeader {
    color: #005695;
    border-top: 1px solid rgb(204, 204, 204);
    margin-top: 2px;
    padding-top: 2px;
    font-weight: normal;
    font-size: 11px;
  }

  >>>.feeScheduleData {
    border-top: 1px solid rgb(204, 204, 204);
    padding-top: 5px;
    font-size: 11px;
    position: absolute;
    bottom: 0px;
    margin: 10px 0px 10px 0px;
    width: 100%;
    color: #005695;
  }

  >>>#cptGrid.truCodeEnabled td {
    position: relative;
    padding-bottom: 32px;
  }
</style>
